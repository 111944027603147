import Img1 from '../../images/placeholders/Placeholders.png';
import Img2 from '../../images/placeholders/Placeholders1.png';
import Img3 from '../../images/placeholders/Placeholders3.png';
import Img4 from '../../images/placeholders/Placeholders3.png';
import Img5 from '../../images/placeholders/Placeholders4.png';
import Img6 from '../../images/placeholders/Placeholders5.png';


import React from 'react';
import PropTypes from 'prop-types';
function randomize(myArray) {
  return myArray[Math.floor(Math.random() * myArray.length)];
}
function RandomImage() {
  const arr = [
    Img1,
    Img2,
    Img3,
    Img4,
    Img5,
    Img6,
    // Img7,
    // Img8,
    // Img9,
    // Img10,
    // Img11,
    // Img12,
    // Img13,
    // Img14,
    // Img15,
    // Img16,
    // Img17,
    // Img18,
    // Img19,
    // Img20,
    // Img21,
    // Img22,
    // Img23,
    // Img24,
    // Img25,
    // Img26,
    // Img27,
    // Img28,
    // Img29,
    // Img30,
    // Img31,
    // Img32,
    // Img33,
    // Img34,
    // Img35,
    // Img36,
    // Img37,
    // Img38,
    // Img39,
    // Img40,
    // Img41,
    // Img42,
    // Img43,
    // Img44,
  ];
  return randomize(arr);
}

export default RandomImage;
