/*
 * HomePage
 *
 * This is the first thing users see of our App, at the '/' route
 */
import { push } from "connected-react-router";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import injectReducer from "../../utils/injectReducer";
import _ from "lodash";
import LoadingIndicator from "../../components/LoadingIndicator";
import List from "../../components/List";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import classNames from "classnames";
import RandomImage from "../../components/RandomImage";
import Avatar from "@material-ui/core/Avatar";
import injectSaga from "../../utils/injectSaga";
import H2 from "../../components/H2";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";

import moment from "moment/min/moment-with-locales";
import "moment/locale/sv";
import Button from "@material-ui/core/Button";
import rullstolsv from "../../images/rullstolsv.png";
import synskadade from "../../images/synskadade.png";
import horslinga from "../../images/horslinga.png";
import {
  deleteEvent,
  addCategoryEvenemang,
  loadEvenemang,
} from "../App/actions";
import {
  loadEvenemangSingle,
  closeDialog,
  openDialog,
  setTabValue,
  setTabValueTitle,
} from "./actions";
import {
  makeSelectEvenemang,
  makeSelectSubEvenemang,
  getProfile,
  getOpen,
  getTabValue,
  getTabValueTitle,
} from "./selectors";
import PlaceIcon from "@mui/icons-material/Place";
import LinkIcon from "@mui/icons-material/Link";
import reducer from "./reducer";
import saga from "./saga";
import { makeSelectCurrentUser, makeSelectUserId } from "../App/selectors";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import { API_ROOT } from "../../api-config";
import Section from "./Section";

const styles = (theme) => ({
  card: {
    marginTop: "1em",
  },
  eventtitle: {
    // marginTop: "2.4em",
    // marginBottom: "2.4em",
  },
  cardcontent: {
    marginLeft: "0em",
    marginRight: "0em",
    paddingLeft: "2em",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0em",
      marginRight: "0em",
      paddingLeft: "0",
    },
  },
  tabContent: {
    float: "none",
    clear: "both",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  categorylink: {
    cursor: "pointer",
    color: "#f57d6c",
  },
  notvisible: {
    display: "none",
  },
  centerLabel: {
    textAlign: "center",
    paddingTop: "5em",
  },
  bigAvatar: {
    width: 150,
    height: 150,
    marginTop: 40,
  },
  icon: {
    fontSize: 32,
  },
  avatar: {
    marginTop: "1.8em",
    height:"32px",
    width:"32px",
  },
  loader: {
    backgroundColor: "transparent",
    border: "none",
  },
  topSection: {
    margin: "0 auto",
    padding: "4em",
    maxWidth: "1600px",
    backgroundColor: "#fff",
    [theme.breakpoints.down("sm")]: {
      padding: "1em",
      paddingTop: "4em",
    },
  },
  belowSection: {
    margin: "0 auto",
    maxWidth: "1600px",
  },
  belowcontainer: {
    backgroundColor: "#ffffff",
    marginTop: "-4em",
  },
  belowSectionContent: {
    // paddingTop: "4em",
    padding: "0 4em",
    [theme.breakpoints.down("sm")]: {
      padding: "0 1em",
    },  
  },
  iconcolor: {
    marginRight: "0.5em",
    color: "#666",
  },
  title: {
    textAlign: "left",
    paddingLeft: "1em",
  },
  tabRoot: {
    minWidth: "50px",
    textTransform: "uppercase",
    fontWeight: "900",
    fontSize: "16px", // Some CSS
    fontFamily: ["Barlow"].join(","),
    textTransform: "uppercase",
    marginRight: theme.spacing(1),
    color: "#333",
    "&:hover": {
      color: "#000",
      opacity: 1,
    },
    "&$tabSelected": {
      color: "#000",
      fontWeight: "900",
    },
    "&:focus": {
      color: "#000",
    },
  },
  headercontainer: {
    backgroundColor: "rgba(227,222,250,1)",
  },
  tabSelected: {},
  floatright: {
    float: "right",
  },
  actions: {
    top: 0,
    right: 0,
  [theme.breakpoints.down("sm")]: {
    top: '5em',
    right: '1em',
    position: 'absolute',
  },
  },
});

export class EvenemangSingle extends React.PureComponent {
  componentDidMount() {
    const singleid = this.props.match.params.id;
    this.props.onSubmitForm(singleid);
    this.tempimage = RandomImage();
    this.tempimage2 = RandomImage();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
        window.scrollTo(0, 0);
    }
  }

  handleBack() {
    this.props.history.push("/");
  }
  handleItemClick(id) {
    this.props.history.push(`/evenemang/${id}`);
  }
  handleItemClickProfile(evt, id) {
    this.props.history.push(`/profile/${id}`);
  }
  handleItemClickEdit(evt, id) {
    evt.stopPropagation();
    this.props.history.push(`/evenemang/${id}/edit`);
  }
  render() {
    const metas = [
      { id: "fysisk", name: "RULLSTOLS VÄNLIGT", imgtag: "horslinga" },
      {
        id: "syn",
        name: "Service till hjälp för synskadade",
        imgtag: "horslinga",
      },
      { id: "horsel", name: "HÖRSLINGA", imgtag: "horslinga" },
    ];
    const {
      classes,
      evenemang,
      subevenemang,
      user,
      userid,
      profile,
      openvariable,
      tabvalue,
      tabvaluetitle,
    } = this.props;
    const loading = false;
    const listview = false;
    const masonryview = true;
    const error = false;
    if (evenemang) {
      const item = evenemang;
      const evenemangListProps = {
        loading,
        error,
        evenemang,
        listview,
        masonryview,
      };
      const {
        name,
        description,
        short_description,
        start_time,
        end_time,
        location,
        id,
        images,
        keywords,
        external_links,
        offers,
        custom_data,
        sub_events,
        data_source,
        provider,
      } = evenemang;
      const { address_locality, street_address, postal_code } = location;
      const groups = [
        "kulturforum:1",
        "kulturforum:2",
        "kulturforum:3",
        "kulturforum:4",
      ];

      let pricelist = "";
      if (offers.length > 0) {
        pricelist = offers.map((list, index) => {
          const {
            is_free,
            info_url,
            description: pricedescription,
            price,
          } = list;

          let pricevalue = "";
          if (price && price.hasOwnProperty("sv") && price.sv != undefined) {
            pricevalue = price.sv;
            if (pricevalue == "0" && is_free) {
              pricevalue = "";
            } else if (pricevalue == 0) {
              pricevalue = "";
            } else {
              pricevalue = pricevalue;
            }
          } else if (is_free) {
            pricevalue = "";
          }

          let pricedescriptionvalue = "";
          if (
            pricedescription &&
            pricedescription.hasOwnProperty("sv") &&
            pricedescription.sv != undefined
          ) {
            pricedescriptionvalue = pricedescription.sv;
          }

          let info_urlvalue = "";
          if (
            info_url &&
            info_url.hasOwnProperty("sv") &&
            info_url.sv != undefined
          ) {
            if (is_free) {
              if (
                info_url &&
                info_url.sv != "" &&
                info_url.sv.indexOf("@") == -1
              ) {
                info_urlvalue =
                  `<a href="${info_url.sv}">` + `Anmälan` + `</a>`;
              } else if (info_url.sv.includes("@")) {
                info_urlvalue =
                  `<a href="mailto:${info_url.sv}">` + `Anmälan` + `</a>`;
              } else {
                info_urlvalue = "";
              }
            } else {
              if (
                info_url &&
                info_url.sv != "" &&
                info_url.sv.indexOf("@") == -1
              ) {
                info_urlvalue = `<a href="${info_url.sv}">${pricevalue} ${pricedescriptionvalue}</a>`;
              } else if (info_url.sv.indexOf("@") != -1) {
                info_urlvalue =
                  `<a href="mailto:${info_url.sv}">` + `Anmälan` + `</a>`;
              } else {
                info_urlvalue = `<p>${pricevalue} ${pricedescriptionvalue}</p>`;
              }
            }
          } else {
            if (is_free) {
              info_urlvalue = "";
            } else {
              info_urlvalue = `<p>${pricevalue} ${pricedescriptionvalue}</p>`;
            }
          }

          if (info_urlvalue == "" && pricevalue == "") {
            return <p key={index}>Gratis</p>;
          } else if (info_url != null && info_url.sv != "") {
            return (
              <p key={index}>
                <LinkIcon />
                &nbsp;
                <span dangerouslySetInnerHTML={{ __html: info_urlvalue }} />
              </p>
            );
          } else {
            return (
              <p key={index}>
                <span dangerouslySetInnerHTML={{ __html: info_urlvalue }} />
              </p>
            );
          }
        });
      }

      let providerinfo = "";
      if (provider && provider.hasOwnProperty("sv")) {
        providerinfo = (<>{provider.sv}</>);
      }

      let origin_uid = null;
      if (custom_data && custom_data.hasOwnProperty("origin_uid")) {
        origin_uid = custom_data.origin_uid;
      }

      let origin_link = null;
      if (custom_data && custom_data.hasOwnProperty("origin_link")) {
        origin_link = custom_data.origin_link;
      }

      let language = null;
      if (
        custom_data &&
        custom_data.hasOwnProperty("language") &&
        custom_data.language != "utan"
      ) {
        language = <p>{custom_data.language}</p>;
      }
      let custom_open = null;
      if (
        custom_data &&
        custom_data.hasOwnProperty("custom_open") &&
        custom_data.custom_open != ""
      ) {
        custom_open = (
          <p dangerouslySetInnerHTML={{ __html: custom_data.custom_open }}></p>
        );
      }
      let city = "";
      if (
        address_locality &&
        address_locality.hasOwnProperty("sv") &&
        address_locality.sv != undefined
      ) {
        city = address_locality.sv;
      }
      let street = "";
      if (
        street_address &&
        street_address.hasOwnProperty("sv") &&
        street_address.sv != undefined
      ) {
        street = street_address.sv;
      }
      let locationname = "";
      if (
        location.name &&
        location.name.hasOwnProperty("sv") &&
        location.name.sv != undefined
      ) {
        locationname = location.name.sv;
      }
      let sv_description = "";
      let fi_description = "";
      let en_description = "";

      let sv_title = "";
      let fi_title = "";
      let en_title = "";

      let short_descriptionfi = "";
      if (
        description &&
        description.hasOwnProperty("sv") &&
        description.sv != undefined
      ) {
        sv_description = description.sv
          .replaceAll("&lt;", "<")
          .replaceAll("&gt;", ">");
      }
      if (
        description &&
        description.hasOwnProperty("fi") &&
        description.fi != undefined
      ) {
        fi_description = description.fi
          .replaceAll("&lt;", "<")
          .replaceAll("&gt;", ">");
      }
      if (
        description &&
        description.hasOwnProperty("en") &&
        description.en != undefined
      ) {
        en_description = description.en
          .replaceAll("&lt;", "<")
          .replaceAll("&gt;", ">");
      }
      if (name && name.hasOwnProperty("sv") && name.sv != undefined) {
        sv_title = name.sv;
      }
      if (name && name.hasOwnProperty("fi") && name.fi != undefined) {
        fi_title = name.fi;
      }
      if (name && name.hasOwnProperty("en") && name.en != undefined) {
        en_title = name.en;
      }

      let eventimage = this.tempimage;
      if (images && images.length > 0) {
        eventimage = images[0].url;
        eventimage = eventimage.replace(/http:/g, "https:");
        eventimage = eventimage.replace(/WxH/g, "1920x1080");
      }

      // let profiletitle = "";
      // if (profile) {
      //     profiletitle = profile.name;
      // } 

      let profileimage = this.tempimage2;

      // if (
      //   profile &&
      //   profile.hasOwnProperty("posterimages") &&
      //   profile.posterimages.length > 0
      // ) {
      //   const profileimageapi = _.find(profile.posterimages, {
      //     role: "profile",
      //   });

      //   if (profileimageapi) {
      //     profileimage = profileimageapi.thumbnail;
      //     profileimage = API_ROOT.replace(/\/$/, "") + profileimage;
      //     profileimage = profileimage.replace(/WxH/g, "440x440");
      //   }
      // }

      if (
        short_description &&
        short_description.hasOwnProperty("sv") &&
        short_description.sv != undefined
      ) {
        short_descriptionfi = short_descriptionfi.sv;
      } else {
        short_descriptionfi = sv_description.substr(0, 250);
      }
      const toTitleCase = (phrase) => {
        return phrase
          .toLowerCase()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
      };
      let parsedStart = "";
      let parsedEnd = "";
      moment.locale("sv");
      if (start_time) {
        parsedStart = toTitleCase(moment(start_time).format("MMM D YYYY"));
      } else {
        parsedEnd = "";
      }
      if (end_time) {
        parsedEnd = `- ${toTitleCase(moment(end_time).format("MMM D YYYY"))}`;
      } else {
        parsedEnd = "";
      }
      let parsedStartTime = "";
      let parsedEndTime = "";
      if (start_time && moment(start_time).format("HH:mm") != "00:00") {
        parsedStartTime = moment(start_time).format("HH:mm");
      } else {
        parsedStartTime = "";
      }
      if (end_time && moment(end_time).format("HH:mm") != "00:00") {
        parsedEndTime = `- ${moment(end_time).format("HH:mm")}`;
      } else {
        parsedEndTime = "";
      }

      let address_sv = "";
      let locale_sv = "";

      if (location && location.street_address) {
        if ("sv" in location.street_address) {
          address_sv = location.street_address.sv;
        } else if ("fi" in location.street_address) {
          address_sv = location.street_address.fi;
        } else if ("en" in location.street_address) {
          address_sv = location.street_address.en;
        }
      }
      if (location && location.address_locality) {
        if ("sv" in location.address_locality) {
          locale_sv = location.address_locality.sv;
        } else if ("fi" in location.address_locality) {
          locale_sv = location.address_locality.fi;
        } else if ("en" in location.address_locality) {
          locale_sv = location.address_locality.en;
        }
      }
      if (location && location.name) {
        if ("sv" in location.name) {
          locationname = location.name.sv;
        } else if ("fi" in location.name) {
          locationname = location.name.fi;
        } else if ("en" in location.name) {
          locationname = location.name.en;
        }
      }
      let selectedgroups = [];
      let keywordlist = "";
      if (keywords.length > 0) {
        keywordlist = keywords.map((list) => {
          let listname = "";
          if (_.indexOf(groups, list.id) > -1) {
            selectedgroups.push(list);
          } else {
            const listname = list.name && "fi" in list.name ? list.name.fi : "";

            return (
              <a
                className={classes.categorylink}
                onClick={(evt) => {
                  this.props.addCategory(evt, list.id, listname);
                }}
                key={list.id}
              >
                #{listname}{" "}
              </a>
            );
          }
        });
      }

      let grouplist = "";
      if (selectedgroups.length > 0) {
        grouplist = selectedgroups.map((list) => {
          let listname = "";
          listname = list.name && "fi" in list.name ? list.name.fi : "";
          return (
            <a
              className={classes.categorylink}
              onClick={(evt) => {
                this.props.addCategory(evt, list.id, listname);
              }}
              key={list.id}
            >
              #{listname}{" "}
            </a>
          );
        });
      }

      let subeventlist = "";
      let subeventlist2 = [];
      if (subevenemang && subevenemang.length > 0 && sub_events.length > 0) {
        let subEvents = _.groupBy(subevenemang, (b) =>
          moment(b.start_time).startOf("month")
        );

        // _.values(grouped_items).forEach((arr) =>
        //   arr.sort((a, b) => moment(a.start_time) - moment(b.start_time))
        // );
        _.mapKeys(subEvents, function (value, key) {
          subeventlist2 +=
            "<strong><p>" +
            toTitleCase(moment(key).format("MMMM YYYY")) +
            "</p></strong>";
          value.map((list) => {
            let sub_starttime = list.start_time
              ? moment(list.start_time).format("D.M.YYYY HH:mm")
              : "";
            let sub_endtime = list.end_time
              ? moment(list.end_time).format("D.M.YYYY HH:mm")
              : "";
            if(moment(list.start_time).format('D.M.') != moment(list.end_time).format('D.M.')){   
            subeventlist2 +=
              "<p>" + sub_starttime + " - " + sub_endtime + "</p>";
            } else {
            subeventlist2 +=
              "<p>" + sub_starttime + " - " + moment(list.end_time).format('HH:mm')+"</p>";
            }  
        });
        });
        subeventlist = subevenemang.map((list) => {
          let sub_starttime = list.start_time
            ? moment(list.start_time).format("D.M.YYYY HH:mm")
            : "";
          let sub_endtime = list.end_time
            ? moment(list.end_time).format("D.M.YYYY HH:mm")
            : "";

          return (
            <p key={list.id + "-time"}>
              {sub_starttime} - {sub_endtime}{" "}
            </p>
          );
        });
      } else {
        subeventlist = (
          <span>
            <LoadingIndicator />
          </span>
        );
      }

      let subeventlistsection = "";
      if (subevenemang && subevenemang.length > 0 && sub_events.length > 0) {
        subeventlistsection = (
          <div>
            <h3>Enskilda evenemang</h3>
            {subeventlist2}
          </div>
        );
      }
      let accessability = "";

      const metarearray = [];

      _.forEach(custom_data, function (v, k) {
        if (k.includes("accessibility_") && v) {
          k = k.replace("accessibility_", "");
          metarearray.push(k);
        }
      });
      const metalist = metarearray.map((list) => {
        if (metarearray) {
          let Tag = <img src={rullstolsv} style={{ width: "35px" }} />;
          if (list == "fysisk") {
            Tag = <img src={rullstolsv} style={{ width: "35px" }} />;
          } else if (list == "syn") {
            Tag = <img src={synskadade} style={{ width: "35px" }} />;
          } else {
            Tag = <img src={horslinga} style={{ width: "35px" }} />;
          }

          return <span>{Tag}</span>;
        }
      });

      const links = external_links.map((list, index) => {
        if (external_links.length > 0) {
          let linkname = list.name;
          if (linkname.includes("social")) {
            linkname = linkname.replace("social_", "");
            const linkaddress = list.link;
            const keyvalue = "social_" + index;
            return (
              <p key={index + "-link"}>
                <LinkIcon />
                &nbsp;
                <a href={linkaddress}>{linkname}</a>
              </p>
            );
          }
        }
      });

      let linksection = "";
      if (external_links.length > 0) {
        linksection = (
          <div>
            <h3>Länkar</h3>
            {links}
          </div>
        );
      }
      let editbutton = "";
      if (
        (user && origin_uid && data_source == "kulturforum") ||
        (data_source == "kulturforum" && origin_link)
      ) {
        if (origin_uid == userid || user.is_staff) {
          editbutton = (
            <Button
              onClick={(evt) => {
                this.handleItemClickEdit(evt, id);
              }}
              variant="text"
              size="small"
              className={classes.editbutton}
            >
              <EditIcon className={classes.leftIcon} />
              REDIGERA
            </Button>
          );
        }
      }

      /*    let deletebutton = '';
      if (user && origin_uid && data_source == 'kulturforum') {
        if (origin_uid == userid || user.is_staff) {
          deletebutton = (
            <Button
              onClick={evt => {
                this.props.deleteEvent(evt, id);
              }}
              variant="text"
              size="small"
              className={classes.deletebutton}
            >
              <DeleteIcon className={classes.leftIcon} />
              TA BORT
            </Button>
          );
        }
      }*/

      let subeventamount = "";

      if (subevenemang && subevenemang.length > 0 && sub_events.length > 0) {
        subeventamount = "(" + subevenemang.length + " Datum )";
      }
      let deletebutton = "";
      if (user && origin_uid && data_source == "kulturforum") {
        if (origin_uid == userid || user.is_staff) {
          deletebutton = (
            <Button
              onClick={(evt) => {
                this.props.openDialog();
              }}
              variant="text"
              size="small"
              className={classes.deletebutton}
            >
              <DeleteIcon className={classes.leftIcon} />
              TA BORT
            </Button>
          );
        }
      }
      let profileaddresscounter = 0;
      const profiles = external_links.map((list, index) => {
        if (external_links.length > 0) {
          let profilename = list.name;
          if (profilename.includes("profile")) {
            profilename = profilename.replace("profile_", "");
            let profileddress = list.link;
            profileddress = decodeURIComponent(
              profileddress.replace("http://remove.me/", "")
            );
            ++profileaddresscounter;
            if (
              profileddress.includes("http:") ||
              profileddress.includes("https:")
            ) {
              let hostname =
                window && window.location && window.location.hostname;
              if (hostname == "dev.kulturforum.fi" || hostname == "localhost") {
                profileddress = profileddress.replace(
                  "http://kulturforum.fi",
                  "https://" + "dev.kulturforum.fi"
                );
              } else {
                profileddress = profileddress.replace("http://", "https://");
              }
              return (
                <p key={index + "-profile"}>
                  <LinkIcon />
                  &nbsp;<a href={profileddress}>{profilename}</a>
                </p>
              );
            } else {
              return (
                <p key={index + "-profile"}>
                  {profilename} - {profileddress}
                </p>
              );
            }
          }
        }
      });
      let profilessection = "";
      if (profileaddresscounter > 0) {
        profilessection = (
          <div>
            <h3>Arrangörer</h3>
            {profiles}
          </div>
        );
      }

      let openingtime = (
        <span>
          {parsedStartTime}
          {parsedEndTime}
        </span>
      );

      return (
        <div>
          <div className={classes.headercontainer}>
            <Section className={classes.topSection}>
              <Grid container>
                <Grid item={true} xs={12} md={8}>
                  <CardMedia
                    className={classes.media}
                    image={eventimage}
                    title="Contemplative"
                  />
                </Grid>

                <Grid
                  item={true}
                  className={classes.cardcontent}
                  xs={12}
                  md={4}
                >
                  <Tabs
                    classes={{
                      root: classes.floatright,
                      flexContainer: classes.tabContainer,
                    }}
                    value={tabvaluetitle}
                    onChange={this.props.setTabValueTitle}
                  >
                    {fi_title == "" && en_title == "" ? null : (
                      <Tab
                        classes={{
                          root: classes.tabRoot,
                          selected: classes.tabSelected,
                        }}
                        label="SV"
                      />
                    )}
                    {en_title != "" ? (
                      <Tab
                        classes={{
                          root: classes.tabRoot,
                          selected: classes.tabSelected,
                        }}
                        label="EN"
                      />
                    ) : (
                      <Tab
                        className={classes.notvisible}
                        classes={{
                          root: classes.tabRoot,
                          selected: classes.tabSelected,
                        }}
                        label="EN"
                      />
                    )}
                    {fi_title != "" ? (
                      <Tab
                        classes={{
                          root: classes.tabRoot,
                          selected: classes.tabSelected,
                        }}
                        label="FI"
                      />
                    ) : (
                      <Tab
                        className={classes.notvisible}
                        classes={{
                          root: classes.tabRoot,
                          selected: classes.tabSelected,
                        }}
                        label="FI"
                      />
                    )}
                  </Tabs>
                  <div className={classes.tabContent}>
                    {(tabvaluetitle === 0 ||
                      (fi_title == "" && en_title == "")) && (
                      <div>
                        <h2 className={classes.eventtitle}>{sv_title}</h2>
                        {providerinfo}
                      </div>
                    )}
                    {tabvaluetitle === 1 && en_title != "" && (
                      <div>
                        <h2 className={classes.eventtitle}>{en_title}</h2>
                        {providerinfo}
                      </div>
                    )}
                    {tabvaluetitle === 2 && fi_title != "" && (
                      <div>
                        <h2 className={classes.eventtitle}>{fi_title}</h2>
                        {providerinfo}

                      </div>
                    )}
                  </div>
                  <p>
                    {parsedStart} {parsedEnd} {subeventamount}
                    <br />
                    {openingtime}
                    <br />
                    <strong>{locationname} </strong>
                    <br />
                    {street} {postal_code} {city}
                  </p>

                  <p>
                    {keywordlist} {grouplist}
                  </p>
                  {/* <Avatar
                    onClick={(evt) => {
                      this.handleItemClickProfile(evt, profile.id);
                    }}
                    src={profileimage}
                    className={classNames(classes.avatar)}
                  /> */}
                  <div className={classes.actions}>
                    {deletebutton}
                    {editbutton}
                  </div>
                </Grid>
              </Grid>
            </Section>
          </div>
          <div className={classes.belowcontainer}>
            <Section className={classes.belowSection}>
              <Grid
                className={classes.belowSectionContent}
                container
                spacing={0}
              >
                <Grid
                  style={{ padding: "0 0em", position: "relative" }}
                  item={true}
                  xs={12}
                  md={8}
                >
                  <Tabs
                    classes={{
                      root: classes.floatright,
                      flexContainer: classes.tabContainer,
                    }}
                    value={tabvalue}
                    onChange={this.props.setTabValue}
                  >
                    {fi_description == "" && en_description == "" ? null : (
                      <Tab
                        classes={{
                          root: classes.tabRoot,
                          selected: classes.tabSelected,
                        }}
                        label="SV"
                      />
                    )}
                    {en_description != "" ? (
                      <Tab
                        classes={{
                          root: classes.tabRoot,
                          selected: classes.tabSelected,
                        }}
                        label="EN"
                      />
                    ) : (
                      <Tab
                        className={classes.notvisible}
                        classes={{
                          root: classes.tabRoot,
                          selected: classes.tabSelected,
                        }}
                        label="EN"
                      />
                    )}
                    {fi_description != "" ? (
                      <Tab
                        classes={{
                          root: classes.tabRoot,
                          selected: classes.tabSelected,
                        }}
                        label="FI"
                      />
                    ) : (
                      <Tab
                        className={classes.notvisible}
                        classes={{
                          root: classes.tabRoot,
                          selected: classes.tabSelected,
                        }}
                        label="FI"
                      />
                    )}
                  </Tabs>
                  <h3>Beskrivning</h3>
                  <div className={classes.tabContent}>
                    {(tabvalue === 0 ||
                      (fi_description == "" && en_description == "")) && (
                      <div>
                        <p
                          dangerouslySetInnerHTML={{ __html: sv_description }}
                        />
                      </div>
                    )}
                    {tabvalue === 1 && en_description != "" && (
                      <div>
                        <p
                          dangerouslySetInnerHTML={{ __html: en_description }}
                        />
                      </div>
                    )}
                    {tabvalue === 2 && fi_description != "" && (
                      <div>
                        <p
                          dangerouslySetInnerHTML={{ __html: fi_description }}
                        />
                      </div>
                    )}
                  </div>

                  <p>{metalist}</p>
                  <h3>Pris €</h3>
                  {pricelist}
                  {linksection}
                  {profilessection}
                </Grid>

                <Grid
                  item={true}
                  xs={12}
                  md={4}
                  style={{ padding: "0 0em", position: "relative" }}
                >
                  <p dangerouslySetInnerHTML={{ __html: subeventlist2 }} />
                  <br />  
                  {custom_open}
                  <br />
                </Grid>
              </Grid>
            </Section>
          </div>

          <Dialog
            open={openvariable}
            onClose={this.props.closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Är du säker på att du vill ta bort evenemanget? "}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                När du tagit bort evenemanget kan det inte återställas.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.props.closeDialog} color="primary">
                Ångra
              </Button>
              <Button
                onClick={(evt) => {
                  this.props.deleteEvent(evt, id);
                }}
                variant="text"
                size="small"
                className={classes.deletebutton}
              >
                <DeleteIcon className={classes.leftIcon} />
                TA BORT
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      );
    }
    return "";
  }
}
EvenemangSingle.propTypes = {
  evenemang: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.bool,
    PropTypes.object,
  ]),
};
const mapStateToProps = createStructuredSelector({
  evenemang: makeSelectEvenemang(),
  subevenemang: makeSelectSubEvenemang(),
  user: makeSelectCurrentUser(),
  userid: makeSelectUserId(),
  profile: getProfile(),
  openvariable: getOpen(),
  tabvalue: getTabValue(),
  tabvaluetitle: getTabValueTitle(),
});

export function mapDispatchToProps(dispatch) {
  return {
    onSubmitForm: (singleid) => {
      dispatch(loadEvenemangSingle(singleid));
    },
    deleteEvent: (evt, id) => {
      dispatch(deleteEvent(id));
      dispatch(closeDialog());
    },
    openDialog: () => {
      dispatch(openDialog());
    },
    setTabValue: (event, value) => {
      dispatch(setTabValue(value));
    },
    setTabValueTitle: (event, value) => {
      dispatch(setTabValueTitle(value));
    },
    closeDialog: () => {
      dispatch(closeDialog());
    },
    addCategory: (evt, id, name) => {
      dispatch(addCategoryEvenemang(id, name));
      dispatch(loadEvenemang());
      dispatch(push(`/evenemang`));
      evt.stopPropagation();
    },
  };
}
const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({ key: "evenemangsingle", reducer });
const withSaga = injectSaga({ key: "evenemangsingle", saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
  withStyles(styles)
)(EvenemangSingle);
