import Img1 from '../../images/hero/pexels-aleksei-pribolovski.jpg';
import Img2 from '../../images/hero/pexels-antti-kulmanen.jpg';
import Img3 from '../../images/hero/pexels-efrem-efre.jpg';
import Img4 from '../../images/hero/pexels-jibarofoto.jpg';
import Img5 from '../../images/hero/pexels-raulling.jpg';
import Img6 from '../../images/hero/pexels-thibault-trillet.jpg';

function randomize(myArray) {
  return myArray[Math.floor(Math.random() * myArray.length)];
}
function RandomImageHome() {
  const arr = [
    Img1,
    Img2,
    Img3,
    Img4,
    Img5,
    Img6,
    
  ];
  return randomize(arr);
}

export default RandomImageHome;
