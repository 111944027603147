/*
 * FavoritePage
 *
 * This is the first thing users see of our App, at the '/' route
 */

import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import FavoriteList from "../../components/FavoriteList";
import injectReducer from "../../utils/injectReducer";
import injectSaga from "../../utils/injectSaga";
import useMediaQuery from "@mui/material/useMediaQuery";
import FavoriteListPrint from "../../components/FavoriteListPrint";

import _ from "lodash";
import Button from "@material-ui/core/Button";
import Stack from "@mui/material/Stack";
import PrintIcon from "@mui/icons-material/Print";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";

import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/styles/withStyles";

import {
  makeSelectCurrentUser,
  makeSelectUserId,
  getFavoriter,
} from "../../containers/App/selectors";
import Chip from "@material-ui/core/Chip";
import DoneIcon from "@mui/icons-material/Done";

import Section from "./Section";
import { loadFavorites, toListView, toMasonryView, setFilter } from "./actions";
import reducer from "./reducer";

import ViewList from "@mui/icons-material/ViewList";
import ViewModule from "@mui/icons-material/ViewModule";
import IconButton from "@material-ui/core/IconButton";
import { Typography } from "@mui/material";
import saga from "./saga";
import {
  getVisibleFavorites,
  getLoading,
  getError,
  getFilter,
  makeSelectListView,
  makeSelectMasonryView,
} from "./selectors";
import { loadFavoriter } from "../App/actions";
const withMediaQuery =
  (...args) =>
  (Component) =>
  (props) => {
    const mediaQuery = useMediaQuery(...args);
    return <Component mediaQuery={mediaQuery} {...props} />;
  };
const styles = (theme) => ({
  alignright: {
    float: "right",
    marginRight: "1em",
    textAlign: "right",
  },
  alignleft: {
    marginLeftt: "1em",
    textAlign: "left",
    float: "left",
    padding: "1em",
  },
  aligncenter: {
    root: {
      display: "inline",
      justifyContent: "center",
      flexWrap: "wrap",
    },
    [theme.breakpoints.up("xs")]: {
      float: "none",
      display: "inline-block", 
    },
    [theme.breakpoints.up("md")]: {
      margin: "0 auto",
      width: "42%",
    },
    textAlign: "center",
  },
  nomarginSection: {
    margin: "0 !important",
    paddingBottom: "2em",
  },
  "@media print": {
    printmargins: {
      margin: "5em 5em 5em 5em",
      padding: "5em 5em 5em 5em",
    },
  },
});

const Printable = () => {
  const matches = useMediaQuery("print");

  return <span>{`print matches: ${matches}`}</span>;
};
export class FavoritePage extends React.PureComponent {
  UNSAFE_componentWillMount() {
    this.props.onSubmitForm();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const {
      classes,
      favoriter,
      favorites,
      listview,
      masonryview,
      userid,
      page,
      user,
      loader,
      error,
      filter,
      mediaQuery,
    } = this.props;
    const reposListProps = {
      favoriter,
      favorites,
      listview,
      masonryview,
      user,
      userid,
      loader,
      filter,
      error,
    };
    const token = localStorage.getItem("token");

    const filters = [
      { id: "EVENT", name: "Evenemang" },
      { id: "NEWS", name: "Nyheter" },
      { id: "PROFILE", name: "Aktörer" },
      { id: "FYRK", name: "Fyrk.fi" },
    ];
    const filterlist = filters.map((list) => {
      if (filter !== list.id) {
        return (
          <Button
            className={classes.periodchip}
            key={list.id}
            onClick={() => {
              this.props.setFilter(list.id);
            }}
          >
            <Typography
              sx={{ textDecoration: "underline" }}
              style={{ fontFamily: "Barlow Semi Condensed", fontSize: "16px" }}
              variant="button"
            >
              {list.name}
            </Typography>
          </Button>
        );
      }
      return (
        <Button
          key={list.id}
          classes={{
            root: classes.selectedChip,
            label: classes.selectedChipLabel,
            icon: classes.selectedChipLabel,
          }}
          startIcon={<DoneIcon />}
          onClick={() => {
            this.props.setFilter("");
          }}
        >
          <Typography
            variant="button"
            style={{ fontFamily: "Barlow Semi Condensed", fontSize: "16px" }}
          >
            {list.name}
          </Typography>
        </Button>
      );
    });

    return (
      <Grid
        container
        justifyContent="flex-start"
        className={classes.root}
        spacing={6}
      >
        <Grid item xs={false} sm={1} />
        <Grid item xs={12} sm={10}>
          <article>
            <Helmet>
              <title>Favoriter</title>
              <meta name="Favoriter" content="" />
            </Helmet>
            {!mediaQuery && (
              <div className={classes.alignright}>
                <IconButton
                  onClick={() => {
                    this.props.onChangeViewMasonry();
                  }}
                >
                  <ViewModule />
                </IconButton>
                <IconButton
                  onClick={() => {
                    this.props.onChangeViewList();
                  }}
                >
                  <ViewList />
                </IconButton>
              </div>
            )}
            {!mediaQuery && (
              <>
              <Button
                variant="contained"
                disableElevation
                onClick={() => {
                  this.props.clearFavorites();
                }}
              >
                {" "}
                &nbsp;RENSA
              </Button>
              {' '}
              <ReactToPrint content={() => this.componentRef}>
              <PrintContextConsumer>
                {({ handlePrint }) => (
                  <Button
                    variant="contained"
                    disableElevation
                    onClick={handlePrint}
                  >
                    <PrintIcon /> &nbsp;SKRIV UT
                  </Button>
                )}
              </PrintContextConsumer>
            </ReactToPrint>
            
            </>
            )}
       

            <div className={classes.aligncenter}>
              {!mediaQuery && (
                <Stack
                  direction={{ xs: "row", sm: "row" }}
                  spacing={{ xs: 0, sm: 1, md: 4 }}
                >
                  {filterlist}
                </Stack>
              )}
            </div>
            <Section
              
              className={classes.printmargins}
            >
              {!mediaQuery && (
                <FavoriteList
                  className={classes.printmargins}
                  printmedia={mediaQuery}
                  {...reposListProps}
                />
              )}
    
              {mediaQuery && (
                
                <FavoriteListPrint
                  ref={el => (this.componentRef = el)} 
                  className={classes.printmargins}
                  {...reposListProps}
                />
              )}
            </Section>
          </article>
        </Grid>
        <Grid item xs={false} sm={1} />
      </Grid>
    );
  }
}

FavoritePage.propTypes = {
  favorites: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  onSubmitForm: PropTypes.func,
};

export function mapDispatchToProps(dispatch) {
  return {
    onChangeViewList: (evt) => dispatch(toListView()),
    onChangeViewMasonry: (evt) => dispatch(toMasonryView()),
    onSubmitForm: () => {
      dispatch(loadFavorites());
    },
    setFilter: (type) => {
      dispatch(setFilter(type));
    },
    onSubmitForm: () => {
      dispatch(loadFavorites());
    },
    clearFavorites: () => {
      localStorage.setItem("cachefavorites", "[]");
      dispatch(loadFavoriter([]));
      dispatch(loadFavorites());
      dispatch(setFilter(""));
    },
  };
}

const mapStateToProps = createStructuredSelector({
  favorites: getVisibleFavorites(),
  favoriter: getFavoriter(),
  userid: makeSelectUserId(),
  user: makeSelectCurrentUser(),
  loader: getLoading(),
  error: getError(),
  filter: getFilter(),
  listview: makeSelectListView(),
  masonryview: makeSelectMasonryView(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({ key: "favoriter", reducer });
const withSaga = injectSaga({ key: "favoriter", saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
  withStyles(styles),
  withMediaQuery("print")
)(FavoritePage);
