/*
 * Evenemanger
 *
 * This is the first thing users see of our App, at the '/' route
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import Button from '@material-ui/core/Button';
import injectReducer from '../../utils/injectReducer';
import injectSaga from '../../utils/injectSaga';
import _ from 'lodash';

import {
  makeSelectEvenemang,
  makeSelectLoading,
  makeSelectError,
  makeSelectCategoriesEvenemangSelectedNames,
  makeSelectCategoriesMainSelectedNamesEvenemang,
  getEventLocationName
} from '../../containers/App/selectors';
import EvenemangList from '../../components/EvenemangList';
import BreadCrumb from '../../components/BreadCrumb';
import List from '../../components/List';
import LoadingIndicator from '../../components/LoadingIndicator';

import CenteredSection from './CenteredSection';
import Section from './Section';
import { loadEvenemang } from '../../containers/App/actions';
import {
  changePage,
  changeUsername,
  toListView,
  toMasonryView,
} from './actions';
import {
  makeSelectUsername,
  makeSelectListView,
  makeSelectMasonryView,
  getPage,
  getMore,
} from './selectors';
import reducer from './reducer';
import saga from './saga';
import ViewList from '@mui/icons-material/ViewList';
import ViewModule from '@mui/icons-material/ViewModule';
import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/styles/withStyles';
import { useMatomo } from '@datapunt/matomo-tracker-react';

import {
  makeSelectCurrentUser,
  makeSelectUserId,
  getFavoriter,
} from '../App/selectors';
import { removeCategoryEvenemang,handleChangeEvenemang,handleChangeEvenemangLocation } from '../App/actions';
import { Grid } from '@material-ui/core';
import H1 from '../../components/H1';

const styles = theme => ({
  alignright: {
    float: 'right',
    marginRight: '1em',
    textAlign: 'right',
  },
  alignleft: {
    marginLeft: '0',
    textAlign: 'left',
    float: 'left',
    marginTop:'-8px'
  },
  nomarginSection: {
    margin: "0 !important" ,
    paddingBottom: '2em',
  },
  morebutton: {
    display: 'inline-block',
    backgroundColor: '#000 !important',
    background: '#000',
    color: '#fff',
    marginLeft: '1em',
    borderRadius: 0,
    marginTop: '16px',
    padding: '4px 8px',
  },
});

export class Evenemanger extends React.PureComponent {
  // eslint-disable-line react/prefer-stateless-function
  /**
   * when initial state username is not null, submit the form to load evenemang
   */

  UNSAFE_componentWillMount() {
    this.props.onSubmitForm();
  }

  render() {
    const {
      loading,
      error,
      evenemang,
      listview,
      masonryview,
      classes,
      breadcrumbvalue1,
      breadcrumbvalue2,
      page,
      user,
      userid,
      favoriter,
      more,
    } = this.props;

    let breadcrumb = [...breadcrumbvalue1, ...breadcrumbvalue2];
    const evenemangListProps = {
      loading,
      error,
      evenemang,
      listview,
      masonryview,
      user,
      userid,
      favoriter,
    };
    let loader = '';
    if (loading) {
      loader = <List component={LoadingIndicator} />;
    }

    const nextpage = page + 1;
    let morebutton = '';
    if (more) {
      morebutton = (
        <Button
          className={classes.morebutton}
          onClick={evt => {
            this.props.onMore(nextpage);
          }}
        >
          {' '}
          MER{' '}
        </Button>
      );
    }

    return (
      <article>
        <Helmet>
          <title>Evenemang</title>
          <meta
            name="Kulturforum"
            content="Evenemang"
          />
        </Helmet>
        <div>
          <Section className={classes.nomarginSection}>
            <Grid container style={{ marginTop: '3em' }}>
              <Grid item sm={10} xs={9}>
                <H1>Evenemang</H1>
                <BreadCrumb breadcrumb={breadcrumb} {...this.props} />
              </Grid>

              <Grid item sm={2} xs={3} style={{marginTop: '20px'}}>
            <IconButton
                onClick={() => {
                  this.props.onChangeViewMasonry();
                }}
              >
                <ViewModule />
              </IconButton>
              <IconButton
                onClick={() => {
                  this.props.onChangeViewList();
                }}
              >
                <ViewList />
              </IconButton>
            </Grid>
            </Grid>
          </Section>
          <Section className={classes.nomarginSection}>
            <EvenemangList {...evenemangListProps} />
          </Section>
          {loader}
          <CenteredSection> {morebutton}</CenteredSection>
        </div>
      </article>
    );
  }
}

Evenemanger.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  evenemang: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  onSubmitForm: PropTypes.func,
  username: PropTypes.string,
  onChangeUsername: PropTypes.func,
};

export function mapDispatchToProps(dispatch) {
  return {
    onChangeViewList: evt => dispatch(toListView()),
    onChangeViewMasonry: evt => dispatch(toMasonryView()),
    onChangeUsername: evt => dispatch(changeUsername(evt.target.value)),
    onSubmitForm: evt => {
      if (evt !== undefined && evt.preventDefault) evt.preventDefault();
      dispatch(loadEvenemang(0));
    },
    onMore: page => {
      dispatch(changePage(page));
      dispatch(loadEvenemang(true));
    },
    removeCategory: (id) => {
      dispatch(removeCategoryEvenemang(id));
      dispatch(handleChangeEvenemang());


      dispatch(loadEvenemang(true));

    },
    removeLocation: (id) => {
      dispatch(handleChangeEvenemangLocation());
      dispatch(loadEvenemang(true));

    },
  };
}

const mapStateToProps = createStructuredSelector({
  evenemang: makeSelectEvenemang(),
  username: makeSelectUsername(),
  loading: makeSelectLoading(),
  error: makeSelectError(),
  listview: makeSelectListView(),
  masonryview: makeSelectMasonryView(),
  breadcrumbvalue1: makeSelectCategoriesEvenemangSelectedNames(),
  breadcrumbvalue2: makeSelectCategoriesMainSelectedNamesEvenemang(),
  page: getPage(),
  user: makeSelectCurrentUser(),
  userid: makeSelectUserId(),
  favoriter: getFavoriter(),
  more: getMore(),
  locationname : getEventLocationName()

});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'evenemanger', reducer });
const withSaga = injectSaga({ key: 'evenemanger', saga });

const EvenemangWithMatomo = (props) => {
  const { trackPageView } = useMatomo();

  React.useEffect(() => {
    trackPageView({});
  }, []);

  return <Evenemanger {...props} />;
};


export default compose(
  withReducer,
  withSaga,
  withConnect,
  withStyles(styles),
)(EvenemangWithMatomo);
