/**
 * RepoListItem
 *
 * Lists the name and the issue count of a repository
 */

import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import { withRouter } from 'react-router-dom';
import moment from 'moment/min/moment-with-locales';
import 'moment/locale/sv';
import RandomImage from '../../components/RandomImage';
import { connect } from 'react-redux';
import { compose } from 'redux';
import WatermarkPlace from '../../images/Place2go.png';
import { deleteEvent,addCategoryEvenemang, loadEvenemang,handleChangeEvenemang,setCurrentEvent,openDialog,selectRouter} from '../App/actions';
import { likeEvent,dislikeEvent } from '../App/actions';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';
import IconButton from '@material-ui/core/IconButton';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Watermark from '../../images/Dethanderiosterbotten.png';
import _ from 'lodash';
import PopFavorite from '../../components/PopFavorite';
import {
  MuiThemeProvider,
  createTheme,
  withStyles,
} from "@material-ui/core/styles";

const theme = createTheme({
  breakpoints: {
    keys: ["xs", "sm", "md", "lg", "xl", "xxl"],
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
      xxl: 2500,
    },
  }
}); 


const styles = {
  card: {
    height: `${100}%`,
    margin: '1em',
    textAlign: 'left',
    overflowWrap: 'break-word',
    backgroundColor: 'transparent',
    // height: '545px',
  },
  cardtitle: {
    overflowWrap: 'break-word',
  },
  cardtime: {
    lineHeight: '0.938rem',
    fontSize: '0.938rem',
  },
  cardtext: {
    minHeight: '200px',
    fontSize: '0.938rem',
    [theme.breakpoints.down('sm')]: {
      minHeight: 'auto',
    },
  },
  griditem: {
    width: '33%',
  },
  iconcolor: {
    marginRight: '0.5em',
    color: '#666',
  },
  actions: {
    display: 'flex',
    position: 'absolute',
    zIndex: '900',
  },
  media: {
    height: 0,
    paddingTop: '56.25%',
    position: 'relative',
  },
  categorylink: {
    cursor: 'pointer',
    color: '#9691BC',
  },
  title: {
    textAlign: 'left',
    paddingLeft: '0',
    marginBottom: 0,
    marginTop: 0,
    overflowWrap: 'break-word',
    minHeight: '32px',
    fontWeight: '600',
    fontSize: '25px'
  },
  cardcontent :{
    paddingLeft : "0",
    paddingRight : "0",
  },
  watermark: {
    position: 'absolute',
    width: '100%',
    top: 0,
    right: 0,
  },
  likebutton: {
    position: 'absolute',
    color: '#F27A68',
    backgroundColor: '#F8F1F2',
    top: '4%',
    right: '5%',
    zIndex: '1000',
  },
  tiny: {
    fontSize: '18px !important',
    marginRight: '4px',
  },
  tiny_bold: {
    fontSize: '18px !important',
    marginRight: '4px',
    fontWeight:'600'
  }
};

export class EvenemangListItemCard extends React.PureComponent {
  UNSAFE_componentWillMount() {
    this.tempimage = RandomImage();
  }
  handleItemClick(id) {
    this.props.history.push(`/evenemang/${id}`);
  }
  handleItemClickEdit(evt, id) {
    evt.stopPropagation();
    this.props.history.push(`/evenemang/${id}/edit`);
  }
  render() {
    const item = this.props.item;
    const {
      name,
      description,
      short_description,
      start_time,
      end_time,
      location,
      id,
      keywords,
      images,
      custom_data,
      data_source
    } = this.props.item;
    const { user, userid,favoriter } = this.props;
    let favoriteids = _.map(favoriter, 'urls');
    let favorite = false;

    if(_.indexOf(favoriteids, item["@id"].replace(/http:/g, 'https:')) != -1) {
      favorite = true;
    }
    let origin_uid = null;
    if (custom_data && custom_data.hasOwnProperty('origin_uid')) {
      origin_uid = custom_data.origin_uid;
    }

    let origin_link = null;
    if (custom_data && custom_data.hasOwnProperty('origin_link')) {
      origin_link = custom_data.origin_link;
    }

    let fi = '';
    let namefi = '';
    let short_desc = '';
    if (
      description &&
      description.hasOwnProperty('sv') &&
      description.fi != undefined
    ) {
      fi = description.fi;
      fi = fi.substr(0, 250);
    }
    if (name && name.hasOwnProperty('sv') && name.sv != undefined) {
      namefi = name.sv;
    } else if (name && name.hasOwnProperty('fi') && name.fi != undefined) {
      namefi = name.fi;
    } else if (name && name.hasOwnProperty('en') && name.en != undefined) {
      namefi = name.en;
    }

    if (short_description && short_description.hasOwnProperty('sv') && short_description.sv != undefined ) {
      short_desc = short_description.sv.substr(0, 250);
    } else if(short_description && short_description.hasOwnProperty('fi') && short_description.fi != undefined ){
      short_desc = short_description.fi.substr(0, 250);
    }else if(short_description && short_description.hasOwnProperty('en') && short_description.en != undefined ){
      short_desc = short_description.en.substr(0, 250);
    }

    const { classes } = this.props;

    let parsedStart = '';
    let parsedEnd = '';

    if (start_time) {
      parsedStart = start_time;
    } else {
      parsedEnd = '';
    }
    if (end_time) {
      parsedEnd = end_time;
    } else {
      parsedEnd = '';
    }
    let address_sv = '';
    let locale_sv = '';
    let locationname_sv = '';

    if (item.hasOwnProperty('location') && location && location.name) {

      if ('sv' in location.name) {
        locationname_sv = location.name.sv;
      } else if ('fi' in location.name){
        locationname_sv = location.name.fi;
      } else if ('en' in location.name){
        locationname_sv = location.name.en;
      }
    }

    if (item.hasOwnProperty('location') && location && location.street_address) {
      if ('sv' in location.street_address) {
        address_sv = location.street_address.sv;
      } else if ('fi' in location.street_address){
        address_sv = location.street_address.fi;
      } else if ('en' in location.street_address){
        address_sv = location.street_address.en;
      }
    }
    if (item.hasOwnProperty('location') && location && location.address_locality) {

      if ('sv' in location.address_locality) {
        locale_sv = location.address_locality.sv;
      } else if ('fi' in location.address_locality){
        locale_sv = location.address_locality.fi;
      } else if ('en' in location.address_locality){
        locale_sv = location.address_locality.en;
      }
    }

    let eventimage = this.tempimage;
    let watermarkimage = "";

    if(data_source == "osterbotten") {
      watermarkimage = (<img className={classes.watermark} src={Watermark} />);
    }

    if(data_source.includes("place2go")) {
      watermarkimage = (<img className={classes.watermark} src={WatermarkPlace} />);
    }

    if (images.length > 0) {
      eventimage = images[0].url;
      eventimage = eventimage.replace(/http:/g, 'https:');
      eventimage = eventimage.replace(/WxH/g, '700x400');
    }
    moment.locale('sv');

    const keywordlist = keywords.map((list,index )=> {
      if (keywords.length > 0 && index < 3) {
        const listname = list.name && 'fi' in list.name ? list.name.fi : '';

        return <a    className={classes.categorylink}
          onClick={(evt) => {
          this.props.addCategory(evt,list.id, listname)
        }} key={list.id}>#{listname} </a>;
      }
    });
    let editbutton = '';
    if (user && origin_uid && data_source == 'kulturforum' || data_source == 'kulturforum' && origin_link) {
      if (origin_uid == userid || user.is_staff) {
        editbutton = (
          <Button
            onClick={evt => {
              this.handleItemClickEdit(evt, item.id);
            }}
            variant="text"
            size="small"
            className={classes.editbutton}
          >
            <EditIcon className={classes.leftIcon} />
            REDIGERA
          </Button>
        );
      }
    }

    let deletebutton = '';
    if (user && origin_uid && data_source == 'kulturforum' || data_source == 'kulturforum' && origin_link) {
      if (origin_uid == userid || user.is_staff) {
        deletebutton = (
          <Button
            onClick={evt => {
              this.props.setCurrentEvent(evt, item.id);
            }}
            variant="text"
            size="small"
            className={classes.deletebutton}
          >
            <DeleteIcon className={classes.leftIcon} />
            TA BORT
          </Button>
        );
      }
    }
    let actionsection = '';
    if (user && origin_uid && data_source == 'kulturforum' || data_source == 'kulturforum' && origin_link) {
      if (origin_uid == userid || user.is_staff) {
        actionsection = (<CardActions className={classes.actions}>
          {deletebutton}
          {editbutton}
        </CardActions>);
      }
    }

    let likebutton = '';
        if(favorite) {
          likebutton = (
            <IconButton  onClick={evt => {
              this.props.dislikeEvent(evt, item.id);
            }} className={classes.likebutton}><Favorite  /></IconButton>
          );

        } else {
          likebutton = (
            <IconButton  onClick={evt => {
              this.props.likeEvent(evt, item.id);
            }} className={classes.likebutton}><PopFavorite /></IconButton>
          );



        }


    

    let timevalue ="";
    let start = moment(parsedStart);
    let end = moment(parsedEnd);
    let datestart = start.format('D.M.YYYY')
    let dateend = end.format('D.M.YYYY')
    if(start.isValid()) {

      timevalue += start.format('D.M.YYYY HH:mm');
    }
    if(end.isValid()) {
      if(datestart != dateend) {
        timevalue += " - " +end.format('D.M.YYYY HH:mm');
      } else {
        timevalue += " - " +end.format('HH:mm');
      }
    }
    let timestring =  "";
    timestring = timevalue.replace(/00:00/g," ")

    return (
      <Card className={classes.card} elevation={0}>
      {actionsection}
        <CardMedia
          onClick={() => {
            this.handleItemClick(item.id);
          }}
          className={classes.media}
          image={eventimage}
          title="Contemplative"
        > {likebutton}{watermarkimage}
        </CardMedia>

        <CardContent className="cardcontent" classes={{ root: classes.cardcontent}}
          onClick={() => {
            this.handleItemClick(item.id);
          }}
        >
          <h5
            className={classes.title}
            dangerouslySetInnerHTML={{ __html: namefi }}
          />
          <p className="cardtext">{short_desc}</p>
          <p className="cardtime">
          <AccessTimeIcon fontSize="small" className="tiny" title="tid"/>
          {timestring}<br/></p>
          <p><LocationOnIcon fontSize="small" className="tiny" title="Plats"/><b>{locationname_sv}</b><br/>
          {address_sv} {locale_sv}</p>
          <p>{keywordlist}</p>
        </CardContent>
      </Card>
    );
  }
}
export function mapDispatchToProps(dispatch) {
  return {
    deleteEvent: (evt, id) => {
      dispatch(deleteEvent(id));
      evt.stopPropagation();
    },
    likeEvent: (evt, id) => {
      dispatch(likeEvent(id, 'EVENT'));
      evt.stopPropagation();
    },
    dislikeEvent: (evt, id) => {
      dispatch(dislikeEvent(id, 'EVENT'));
      evt.stopPropagation();
    },
    setCurrentEvent: (evt, id) => {
      dispatch(setCurrentEvent(id));
      dispatch(openDialog());
      evt.stopPropagation();
    },
    addCategory: (evt, id, name) => {
      dispatch(addCategoryEvenemang(id, name));
      dispatch(loadEvenemang());
      evt.stopPropagation();
    },
  };
}
const withConnect = connect(
  null,
  mapDispatchToProps,
);

EvenemangListItemCard.propTypes = {
  item: PropTypes.object,
  classes: PropTypes.object,
};
export default compose(
  withConnect,
  withRouter,
  withStyles(styles),
)(EvenemangListItemCard);
