import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import injectReducer from '../../utils/injectReducer';
import injectSaga from '../../utils/injectSaga';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import RandomImage from '../../components/RandomImage';
import classNames from 'classnames';
import {
  makeSelectFirstName,
  makeSelectLastName,
  makeSelectCategoriesAll,
  makeSelectPlats,
  makeSelectGroups,
  makeSelectKultur,
  getHelp,
} from '../../containers/App/selectors';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import CenteredSection from './CenteredSection'
import { withStyles } from '@material-ui/core/styles';

import 'moment/locale/sv';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import Select from 'react-select';
import ImageUploader from 'react-images-upload';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import DeleteIcon from '@mui/icons-material/Delete';
import saga from './saga';
import reducer from './reducer';
import {
  makeSelectProfile,
  makeSelectValue,
  makeSelectPosterCategories,
  getSelectedLocations,
  getSelectedGroups,
  makeSelectPictures,
  makeSelectPicturesProfile,
  getDescription,
  getOwnEvents,
  getTitle,
  getGroupName,
  getUrls,
  getContact,
  getPhone,
  getLastName,
  getFirstName,
  getError,
  getFyrk,
  getEditorState,
  getProfiles,
  getSelectedProfiles,
  getHeaderImage,
  getProfileImage,
  getFullProfile,
  getLoading,
} from './selectors';
import { hideLeftDrawer } from '../App/actions';
import { getUserFyrkStatus } from '../../containers/App/selectors';

import {
  loadProfileSingle,
  handleChange,
  handleChangePosterCategory,
  changeDescription,
  changeTitle,
  changeLastName,
  changeFirstName,
  changeLocation,
  changeGroup,
  changeGroupName,
  changeUrls,
  changeContact,
  changePhone,
  submitEditProfileForm,
  imageDrop,
  imageDropProfile,
  changeFyrk,
  changeEditorState,
  // changeProfiles,
  changeFullProfile,
  clearImage,
  clearProfile,
  setLoading,
} from './actions';
import H3 from '../../components/H3';

const styles = theme => ({
  card: {
    marginTop: '1em',
  },
  cardcontent: {
    marginLeft: '3em',
    marginRight: '3em',
  },
  container: {
    width: '80%',
    margin: '0 auto',
  },
  kontaktpaper: {
    padding: theme.spacing(3),
    backgroundColor: '#ffa4a447',
  },
  avatar: {
    margin: 10,
  },
  editor: {
    minHeight: '8em',
    padding: '1em',
  },
  radiogroup: {
    justifyContent: 'center',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  iconcolor: {
    color: '#a49bdd',
  },
  errorStyle: {
    color: '#f44336;',
  },
  title: {
    textAlign: 'left',
    paddingLeft: '1em',
  },
  submit: {
    marginLeft: '1em',
  },
  buttons: {
    marginTop: '2em',
  },
  textField: {
    width: '100%',
  },
  bigAvatar: {
    width: 150,
    height: 150,
    marginTop: 40,
  },
  wysiwyg: {
    border: '1px solid #ccc',
    marginTop: '1em',
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
  },
  paper: {
    padding: '1em',
  },
  section: {
    width: '80%',
    textAlign: 'left',
  },
  tooltip: {
    backgroundColor: '#a49bdd',
    color: '#fff',
    fontSize: '1em',
    maxWidth: '95%',
    margin: '0 auto',
  },
  helpStyle: {
    color: '#9691BC',
    textAlign: 'left',
    marginTop: '-20px',
  },
  SimpleProfile: {
    backgroundColor: ' #fbe8e5',
  },
});

export class ProfileSingleEdit extends React.PureComponent {
  UNSAFE_componentWillMount() {
    this.image = RandomImage();
    this.props.hideSearch();
    this.freq = [
      { id: 'Aldrig', value: 'NONE' },
      { id: '1 per vecka', value: 'WEEKLY' },
      { id: '1 per månad', value: 'MONTHLY' },
    ];
    const singleid = this.props.match.params.id;

    if (singleid) {
      this.props.onSubmitForm(singleid);
    } else if(this.props.location.pathname == "/profile/new"){
      this.props.onSubmitForm(-1);
    } else {
      this.props.onSubmitForm(0);
    }
    this.groupname = React.createRef();
    this.firstname = React.createRef();
    this.description = React.createRef();
    this.lastname = React.createRef();
    this.categories = React.createRef();
    this.scrolled = false;
    this.fyrkstatus = React.createRef();


  }

  openScroll(refe) {
    this.scrolled = false;
  }



  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  handleBack() {
    this.props.history.push('/');
  }

  render() {
    const {
      classes,
      profile,
      value,
      postercategories,
      selectedprofiles,
      groupname,
      pictures,
      profilepictures,
      loading,
      firstname,
      lastname,
      error,
      contact,
      phone,
      urls,
      helptexts,
      editorstate,
      prevheaderimage,
      prevprofileimage,
      fullprofile,
      fyrkstatus
    } = this.props;

    // let completeprofile = profile.profile || fullprofile;
    const completeprofile = 1;

    let { groups } = this.props;
    if (!groups) {
      groups = [];
    }
    let { locations } = this.props;
    if (!locations) {
      locations = [];
    }
    let { categories } = this.props;
    if (!categories) {
      categories = [];
    }
    //
    // let postercategories = this.props.postercategories;
    // if (!postercategories) {
    //   posetercategories = [];
    // }

    // let { profiles } = this.props;
    // if (!profiles) {
    //   profiles = [];
    // }
    const {
      error_firstname,
      error_groupname,
      error_lastname,
      error_profileimage,
      error_headerimage,
      error_description,
      error_categories,
      error_name
    } = error;
    const errorProps = {
      error: true,
    };

    const { posterimages } = profile;
    if (posterimages) {
      const headerimageapi = _.find(posterimages, { role: 'header' });
      const profileimageapi = _.find(posterimages, { role: 'profile' });
    }
    // const kulturvalues = [
    //   { id: 'I dagis', value: 'd' },
    //   { id: 'I skola', value: 's' },
    //   { id: 'Repertoar Live', value: 'l' },
    // ];

    let headerimage = this.image;
    let profileimage = this.image;

    if (prevheaderimage != false) {
      headerimage = prevheaderimage;
    }
    if (prevprofileimage != false) {
      profileimage = prevprofileimage;
    }

    let buttonvalue = 'Create';
    if (profile.id) {
      buttonvalue = 'Spara';
    } else {
      buttonvalue = 'Skapa';
    }
    if(!this.scrolled && error && error.error_firstname && error_firstname != "") {
      if(this.firstname.current) {
      this.firstname.current.scrollIntoView({ behavior: 'smooth' });
      this.scrolled = true;
      }
    }
    if(!this.scrolled && error && error.error_lastname && error_lastname != "") {
      if(this.lastname.current) {
      this.lastname.current.scrollIntoView({ behavior: 'smooth' });
      this.scrolled = true;
      }
    }
    if(!this.scrolled && error && error.error_groupname && error_groupname != "") {
      if(this.groupname.current) {
      this.groupname.current.scrollIntoView({ behavior: 'smooth' });
      this.scrolled = true;
      }
    }
    if(!this.scrolled && error && error.error_description && error_description != "") {
      if(this.description.current) {
      this.description.current.scrollIntoView({ behavior: 'smooth' });
      this.scrolled = true;
      }
    }
    if(!this.scrolled && error && error.error_categories && error_categories != "") {
      if(this.description.current) {
      this.categories.current.scrollIntoView({ behavior: 'smooth' });
      this.scrolled = true;
      }
    }
    const evenemanglist = null;

    const fyrkselect = null;

    const imageContainerStyle = {
      background: 'transparent',
      boxShadow: 'none',
    };
    let profil_info = '';
    if (helptexts) {
      const temptext = _.find(helptexts, { slug: 'profil_info' });
      if (temptext) {
        profil_info = temptext.description;
      }
    }

    let kulturprofil_info = '';
    if (helptexts) {
      const temptext = _.find(helptexts, { slug: 'kulturprofil_info' });
      if (temptext) {
        kulturprofil_info = temptext.description;
      }
    }
    let andvandar_profil = '';
    if (helptexts) {
      const temptext = _.find(helptexts, { slug: 'profil_anvandar' });
      if (temptext) {
        andvandar_profil = temptext.description;
      }
    }

    let kontakt_info = '';
    if (helptexts) {
      const temptext = _.find(helptexts, { slug: 'kontakt_info' });
      if (temptext) {
        kontakt_info = temptext.description;
      }
    }

    let profil_image = '';
    if (helptexts) {
      const temptext = _.find(helptexts, { slug: 'profil_image' });
      if (temptext) {
        profil_image = temptext.description;
      }
    }

    let profil_icon = '';
    if (helptexts) {
      const temptext = _.find(helptexts, { slug: 'profil_icon' });
      if (temptext) {
        profil_icon = temptext.description;
      }
    }

    let profil_news = '';
    if (helptexts) {
      const temptext = _.find(helptexts, { slug: 'profil_news' });
      if (temptext) {
        profil_news = temptext.description;
      }
    }

    let profil_regions = '';
    if (helptexts) {
      if (completeprofile) {
        const temptext = _.find(helptexts, { slug: 'profil_regions' });
        if (temptext) {
          profil_regions = temptext.description;
        }
      } else {
        const temptext = _.find(helptexts, { slug: 'profil_region' });
        if (temptext) {
          profil_regions = temptext.description;
        }
      }
    }

    let profil_profil_cats = '';
    if (helptexts) {
      if (completeprofile) {
        const temptext = _.find(helptexts, { slug: 'profil_profil_cats' });
        if (temptext) {
          profil_profil_cats = temptext.description;
        }
      }
    }
    let profil_news_cats = '';
    if (helptexts) {
      if (completeprofile) {
        const temptext = _.find(helptexts, { slug: 'profil_cats' });
        if (temptext) {
          profil_news_cats = temptext.description;
        }
      } else {
        const temptext = _.find(helptexts, { slug: 'profil_cat' });
        if (temptext) {
          profil_news_cats = temptext.description;
        }
      }
    }
    let profil_groups = '';
    if (helptexts) {
      if (completeprofile) {
        const temptext = _.find(helptexts, { slug: 'profil_groups' });
        if (temptext) {
          profil_groups = temptext.description;
        }
      } else {
        const temptext = _.find(helptexts, { slug: 'profil_group' });
        if (temptext) {
          profil_groups = temptext.description;
        }
      }
    }

    let profil_links = '';
    if (helptexts) {
      const temptext = _.find(helptexts, { slug: 'profil_links' });
      if (temptext) {
        profil_links = temptext.description;
      }
    }

    let profil_desc = '';
    if (helptexts) {
      const temptext = _.find(helptexts, { slug: 'profil_desc' });
      if (temptext) {
        profil_desc = temptext.description;
      }
    }

   /* let profil_kultur = '';
    if (helptexts) {
      const temptext = _.find(helptexts, { slug: 'profil_kultur' });
      if (temptext) {
        profil_kultur = temptext.description;
      }
    }
*/
    let kulturhelp = '';
    if (helptexts) {
      const kulturtemptext = _.find(helptexts, { slug: 'tooltip_kultur' });
      if (kulturtemptext) {
        kulturhelp = kulturtemptext.description;
      }
    }
    if(!this.scrolled && error && error.error_name && error_name != "") {
      if(this.name.current) {
      this.name.current.scrollIntoView({ behavior: 'smooth' });
      this.scrolled = true;
      }
    }

    let headerimagecontainer = '';
    let namescontainer = '';
    let kulturcontainer = '';
    if (!this.fyrkstatus) {
      headerimagecontainer = (
      <>
      <Grid item xs={false} md={1} />
      <Grid item xs={12} >
        <Grid container>
          <Grid item xs={12} md={6} >
                <CardMedia className={classes.media} image={headerimage} />
                <Button
                  onClick={evt => {
                    this.props.clearImage();
                  }}
                  variant="text"
                  size="small"
                  className={classes.deletebutton}
                >
                  <DeleteIcon className={classes.leftIcon} />
                </Button>
                <span className={classes.errorStyle}> {error_headerimage}</span>
          </Grid>
              <Grid xs={12} md={6}>
                <ImageUploader
                  singleImage
                  withPreview={false}
                  withIcon={false}
                  label=""
                  buttonText="Ladda upp bild"
                  onChange={this.props.imageDrop}
                  imgExtension={['.jpg', '.jpeg', '.png', '.gif']}
                  maxFileSize={5242880}
                  fileContainerStyle={imageContainerStyle}
                  name="test"
                />
                <p
                  className={classes.helpStyle}
                  style={{ textAlign: 'center' }}
                >
                  {profil_image}
                </p>
            </Grid>
          </Grid>
        </Grid>
                </>);

     namescontainer = (
      <>
                    <Grid ref={this.firstname} item xs={12} md={6}>
                <TextField
                  id="Förnamn"
                  label="Förnamn"
                  margin="normal"
                  helperText={error_firstname}
                  FormHelperTextProps={errorProps}
                  className={classes.textField}
                  value={firstname}
                  onChange={this.props.handleChangeFirstName}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ style: { fontSize: '2em' } }}
                />
              </Grid>
              <Grid ref={this.lastname}  item xs={12} md={6}>
                <TextField
                  id="firstname"
                  label="Efternamn"
                  margin="normal"
                  helperText={error_lastname}
                  FormHelperTextProps={errorProps}
                  className={classes.textField}
                  value={lastname}
                  onChange={this.props.handleChangeLastName}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ style: { fontSize: '2em' } }}
                />
              </Grid>
              </>
    );
    kulturcontainer = (
      <>
                  <Grid container spacing={0} >
            <Grid item xs={false} md={2} />
            <Grid item xs={12} md={8}>
            <h4>KULTURARBETARE</h4>
            <Paper variant= 'outlined' className={classes.kontaktpaper} xs={12}>
            <p className={classes.helpStyle} style={{ marginTop: '0px' }}
            dangerouslySetInnerHTML={{ __html: kontakt_info }}
            />
            <Grid container spacing={3}>
              <Grid item xs={12} md={5} >
              <TextField
                label="E-post"
                className={classes.textField}
                margin="normal"
                value={contact}
                //missing the validation here
                onChange={this.props.handleChangeContact}
                InputLabelProps={{ shrink: true }}
                InputProps={{ style: { fontSize: '2em' } }}
              />
              </Grid>
              <Grid item xs={false} md={2}></Grid>
              <Grid item xs={12} md={5}>
                <TextField
                  label="Tel."
                  className={classes.textField}
                  margin="normal"
                  value={phone}
                  onChange={this.props.handleChangePhone}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ style: { fontSize: '2em' } }}
                />
                </Grid>
                </Grid>
            </Paper>
            </Grid>
            </Grid>
            </>
    );
    }

    return (
      <div className={classes.container}>
        <form className={classes.form} onSubmit={this.props.onSubmitFormEdit}>
            <Grid container className={classes.root} spacing={8}>
            <Grid item xs={false} md={2} />
              <Grid item xs={12} md={8}>
              <p style={{paddingTop: '2em'}}
                className={classes.helpStyle}
                dangerouslySetInnerHTML={{ __html: andvandar_profil }}
              />
                <H3 style={{ color: '#f57d6c' }}>FYLL I SOM ANVÄNDARPROFIL</H3>

                <p
                  className={classes.helpStyle}
                  dangerouslySetInnerHTML={{ __html: kulturprofil_info }}
                />
                
                <Grid container spacing={4}>
                {headerimagecontainer}
                <Grid item xs={12} md={4}>
                <div className={classes.row}>
                  <Avatar
                    alt="Profile Image"
                    src={profileimage}
                    className={classNames(classes.avatar, classes.bigAvatar)}
                  />
                </div>
                <p>
                <Button
                  onClick={evt => {
                    this.props.clearProfile();
                  }}
                  variant="text"
                  size="small"
                  className={classes.deletebutton}
                >
                  <DeleteIcon className={classes.leftIcon} />
                </Button>
                </p>
                <span className={classes.errorStyle}>{error_profileimage}</span>
                <ImageUploader
                  singleImage
                  withPreview={false}
                  withIcon={false}
                  label=""
                  buttonText="Ladda upp profilbild"
                  onChange={this.props.imageDropProfile}
                  imgExtension={['.jpg', '.jpeg', '.png', '.gif']}
                  maxFileSize={5242880}
                  fileContainerStyle={imageContainerStyle}
                  name="test2"
                />
                <p
                  className={classes.helpStyle}
                  style={{ textAlign: 'center' }}
                >
                {profil_icon}
                </p>
                </Grid>
              </Grid>
              </Grid>
              </Grid>
              <Grid container>
              <Grid item xs={false} md={2} />
              <Grid item xs={12} md={8}>

              <Grid container spacing={4}>
                <Grid ref={this.groupname}  item xs={12}>
                <TextField
                  label="Förening / Organisation / Grupp *"
                  className={classes.textField}
                  margin="normal"
                  helperText={error_groupname}
                  FormHelperTextProps={errorProps}
                  value={groupname}
                  onChange={this.props.handleChangeGroupName}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ style: { fontSize: '2em' } }}
                />
              </Grid>
              {namescontainer}

              <Grid item xs={12}>
              <TextField
                label="Websidan"
                className={classes.textField}
                margin="normal"
                value={urls}
                placeholder="https://"
                //missing the validation here
                onChange={this.props.handleChangeUrls}
                InputLabelProps={{ shrink: true }}
                InputProps={{ style: { fontSize: '2em' } }}
              />
              </Grid>
            </Grid>
            </Grid>
            </Grid>

            <Grid container spacing={4}>
            <Grid item xs={false} md={2} />

              <Grid ref={this.description}  item xs={12} md={8}>
                <h4>BESKRIVNING *</h4>
                <Editor
                  editorState={editorstate}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName={classes.wysiwyg}
                  editorClassName={classes.editor}
                  onEditorStateChange={this.props.onEditorStateChange}
                  stripPastedStyles
                  toolbar={{
                    options: [
                      'inline',
                      'blockType',
                      'fontSize',
                      'fontFamily',
                      'list',
                      'textAlign',
                      'link',
                    ],
                    inline: { inDropdown: true },
                    list: { inDropdown: true },
                    textAlign: { inDropdown: true },
                    link: { inDropdown: true },
                    history: { inDropdown: true },
                  }}
                />
                <span className={classes.errorStyle}>{error_description}</span>
                <p className={classes.helpStyle} style={{ marginTop: '0px' }}>
                  {profil_desc}
                </p>
              </Grid>
              <Grid container spacing={8}>
              <Grid item xs={false} md={2} />
              <Grid item xs={12} md={8}>
                <h4 ref={this.categories}>VILKA KATEGORIER BESKRIVER ER *</h4>
                <Select
                  onChange={this.props.handleChangePosterCategory}
                  name="form-field-name"
                  getOptionLabel={({name}) => name}
                  getOptionValue={({id}) => id}
                  isMulti={true}
                  placeholder="Välj"
                  value={postercategories}
                  options={categories}
              />
                <span className={classes.errorStyle}>{error_categories}</span>
                <p className={classes.helpStyle} style={{ marginTop: '0px' }}>
                  {profil_profil_cats}
                </p>
                
              </Grid>
            </Grid>
            </Grid>

          {kulturcontainer}

          <Grid container spacing={0} className={classes.buttons}>
          <Grid item xs={false} md={2} />
            <Grid item xs={12} md={8}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
            //    disabled={loading}
              >
                {' '}
                {buttonvalue}
              </Button>
            </Grid>

          </Grid>
        </form>
      </div>
    );
  }
}

ProfileSingleEdit.propTypes = {
  evenemang: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
};

const mapStateToProps = createStructuredSelector({
  firstname: makeSelectFirstName(),
  lastname: makeSelectLastName(),
  profile: makeSelectProfile(),
  value: makeSelectValue(),
  postercategories: makeSelectPosterCategories(),
  categories: makeSelectCategoriesAll(),
  selectedlocations: getSelectedLocations(),
  locations: makeSelectPlats(),
  pictures: makeSelectPictures(),
  profilepictures: makeSelectPicturesProfile(),
  groups: makeSelectGroups(),
  groupname: getGroupName(),
  urls: getUrls(),
  contact: getContact(),
  phone: getPhone(),
  selectedgroups: getSelectedGroups(),
  description: getDescription(),
  lastname: getLastName(),
  firstname: getFirstName(),
  title: getTitle(),
  //profiles: makeSelectKultur(),
  error: getError(),
  loading: getLoading(),
  fyrk: getFyrk(),
  editorstate: getEditorState(),
  profiles: getProfiles(),
  selectedprofiles: getSelectedProfiles(),
  helptexts: getHelp(),
  prevheaderimage: getHeaderImage(),
  prevprofileimage: getProfileImage(),
  fullprofile: getFullProfile(),
  fyrkstatus : getUserFyrkStatus(),
});

export function mapDispatchToProps(dispatch) {
  return {
    onSubmitForm: singleid => {
      dispatch(loadProfileSingle(singleid));
    },
    handleChange: value => {
      dispatch(handleChange(value));
    },
    handleChangePosterCategory: value => {
      dispatch(handleChangePosterCategory(value));
    },
    handleChangeDescription: evt => {
      dispatch(changeDescription(evt.target.value));
    },
    handleChangeTitle: evt => {
      dispatch(changeTitle(evt.target.value));
    },
    handleChangeGroupName: evt => {
      dispatch(changeGroupName(evt.target.value));
    },
    handleChangeUrls: evt => {
      dispatch(changeUrls(evt.target.value));
    },
    handleChangeContact: evt => {
      dispatch(changeContact(evt.target.value));
    },
    handleChangePhone: evt => {
      dispatch(changePhone(evt.target.value));
    },
    handleChangeLastName: evt => {
      dispatch(changeLastName(evt.target.value));
    },
    handleChangeFirstName: evt => {
      dispatch(changeFirstName(evt.target.value));
    },
    locationChange: value => {
      dispatch(changeLocation(value));
    },
    groupChange: value => {
      dispatch(changeGroup(value));
    },
    // profilesChange: value => {
    //   dispatch(changeProfiles(value));
    // },
    handleChangeFyrk: value => {
      dispatch(changeFyrk(value));
    },
    handleChangeFullProfile: value => {
      dispatch(changeFullProfile());
    },
    setLoading: value => {
      dispatch(setLoading(value));
    },
    imageDrop: (event, picture) => {
      dispatch(imageDrop(picture));
    },
    hideSearch: evt => {
      dispatch(hideLeftDrawer());
    },
    imageDropProfile: (event, picture) => {
      dispatch(imageDropProfile(picture));
    },
    clearImage: () => {
      dispatch(clearImage());
    },
    clearProfile: () => {
      dispatch(clearProfile());
    },
    onSubmitFormEdit: evt => {
      evt.preventDefault();
      evt.stopPropagation();
      dispatch(submitEditProfileForm());
    },
    onEditorStateChange: editorstate => {
      dispatch(changeEditorState(editorstate));
      if (editorstate) {
        dispatch(
          changeDescription(
            draftToHtml(convertToRaw(editorstate.getCurrentContent())),
          ),
        );
      }
    },
  };
}
const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);
const withReducer = injectReducer({ key: 'profilesingleedit', reducer });
const withSaga = injectSaga({ key: 'profilesingleedit', saga });

export default compose(
  withReducer,
  withSaga,
  withRouter,
  withConnect,
  withStyles(styles),
)(ProfileSingleEdit);
