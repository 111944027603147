/**
 * RepoListItem
 *
 * Lists the name and the issue count of a repository
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import RandomImage from '../../components/RandomImage';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';
import IconButton from '@material-ui/core/IconButton';
import { withRouter } from 'react-router-dom';
import { deleteProfile } from '../App/actions';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { likeEvent,dislikeEvent,loadKultur,addGroup,addCategory } from '../App/actions';
import DeleteIcon from '@mui/icons-material/Delete';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import { API_ROOT } from '../../api-config';
import PopFavorite from '../../components/PopFavorite';
const styles = {
  card: {
    // height: `${100}%`,
    margin: '0em',
    padding: '0em',
    textAlign: 'left',
    backgroundColor: 'transparent',
    //height: '534px',
  },
  griditem: {
    //width: '33%',
  },
  likebutton: {
    color: '#F27A68',
  },
  media: {
    //height: '276px',
    paddingTop: '56.25%', // 16:9
  },
  categorylink: {
    cursor: 'pointer',
    color: '#9691BC',
  },
  cardcontent: {
    paddingLeft: '0',
    paddingRight: '0',
  },
  title: {
    textAlign: 'left',
    paddingLeft: '0',
    marginBottom: 0,
    marginTop: 0,
    overflowWrap: 'break-word',
    minHeight: '32px',
    fontWeight: '600',
    fontSize: '25px'
  },
  likebutton: {
    position: 'absolute',
    color: '#f44336',
    backgroundColor: '#F8F1F2',
    top: '4%',
    right: '5%',
  },
};
export class KulturListItemCard extends React.PureComponent {
  UNSAFE_componentWillMount() {
    this.tempimage = RandomImage();
  }

  handleItemClick(id) {
    this.props.history.push(`/profile/${id}`);
  }

  handleItemClickEdit(evt, id) {
    evt.stopPropagation();
    this.props.history.push(`/profile/${id}/edit`);
  }

  render() {
    const {item} = this.props;
    const {
      firstname,
      lastname,
      groupname,
      posterimages,
      postercategories,
      groups,
    } = this.props.item;
    const { classes, userid, user, favoriter } = this.props;
    const favoriteids = _.map(favoriter, 'urls');
    let favorite = false;

    let itempath = API_ROOT + 'posters/' + item.id + '/';
    if (_.indexOf(favoriteids, itempath) != -1) {
      favorite = true;
    }

    let headerimage = this.tempimage;
    let profileimage = this.tempimage;
    const headerimageapi = _.find(posterimages, { role: 'header' });
    const profileimageapi = _.find(posterimages, { role: 'profile' });
    if (headerimageapi) {
      headerimage = headerimageapi.thumbnail;
      headerimage = API_ROOT.replace(/\/$/, '') + headerimage;
      headerimage = headerimage.replace(/WxH/g, '1000x1000');
    }
    if (profileimageapi) {
      profileimage = profileimageapi.thumbnail;
      profileimage = API_ROOT.replace(/\/$/, '') + profileimage;
      profileimage = profileimage.replace(/WxH/g, '440x440');
    }

    let editbutton = '';
    if (user) {
      if (item.user == userid || user.is_staff) {
        editbutton = (
          <Button
            onClick={evt => {
              this.handleItemClickEdit(evt,item.id);
            }} variant="text" size="small" className={classes.editbutton}>
            <EditIcon className={classes.leftIcon} />
          </Button>);
      }
    }
    let cardtitle = '';
    if (lastname == null && firstname == null) {
      cardtitle = (
        <span>
          <h5 className={classes.title}> {`${groupname}`}</h5>
        </span>
      )
    } else {
      cardtitle = (
        <span>
          <h5 className={classes.title}>
            {' '}
            {`${firstname} ${lastname}`}
            <br />
            {`${groupname}`}</h5>
        </span>
      )
    }



    let deletebutton = '';
    if (user) {
      if (item.user == userid || user.is_staff) {
        deletebutton = (
          <Button
            onClick={evt => {
              this.props.deleteNyhet(evt,item.id);
            }} variant="text" size="small" className={classes.deletebutton}>
            <DeleteIcon className={classes.leftIcon} />
          </Button>);
      }
    }
    let groupslist = null;
    if (groups && groups.length > 0) {
      groupslist = groups.map(list => <a key={list.id} id={list.id} className={classes.categorylink}    onClick={(evt) => {
          this.props.addGroup(evt,list.id, list.name)
        }}>#{list.name} </a>);
    }
    let categorieslist = null;
    if (postercategories && postercategories.length > 0) {
      categorieslist = postercategories.map((list, index) => {
        if (index < 3) {
          return (
            <a
              key={list.id}
              className={classes.categorylink}
              onClick={evt => {
            this.props.addCategory(evt,list.id, list.name)
          }}>#{list.name} </a>);
        }
      });
    }
    let likebutton = '';
      if(favorite) {
        likebutton = (
          <IconButton
onClick={evt => {
            this.props.dislikeKultur(evt, item.id);
          }}
            className={classes.likebutton}
          >
            <Favorite />
          </IconButton>
        );
      } else {
        likebutton = (
          <IconButton
            onClick={evt => {
            this.props.likeKultur(evt, item.id);
          }}
            className={classes.likebutton}
          >
            <PopFavorite />
          </IconButton>
        );
      }
    
    let actionsection = '';
    if (user) {
      if (item.user == userid || user.is_staff) {
        actionsection = (
          <CardActions className={classes.actions} >
            {deletebutton}
            {editbutton}
          </CardActions>
        );
      }
    }

    return (
      <Card className={classes.card} elevation={0}>
        <CardMedia
          onClick={() => {
            this.handleItemClick(item.id);
          }}
          className={classes.media}
          image={profileimage}
        > {likebutton}</CardMedia>

        <CardContent
className="cardcontent" classes={{ root: classes.cardcontent}}
          onClick={() => {
            this.handleItemClick(item.id);
          }}
        >
          {cardtitle}

          <p>{categorieslist} {groupslist}</p>
        </CardContent>
        {actionsection}
      </Card>

    );
  }
}

KulturListItemCard.propTypes = {
  item: PropTypes.object,
};

export function mapDispatchToProps(dispatch) {
  return {
    deleteNyhet: (evt, id) => {
      dispatch(deleteProfile(id));
      evt.stopPropagation();
    },
    likeKultur: (evt, id) => {
      dispatch(likeEvent(id, 'PROFILE'));
      evt.stopPropagation();
    },
    dislikeKultur: (evt, id) => {
      dispatch(dislikeEvent(id, 'PROFILE'));
      evt.stopPropagation();
    },
    addCategory: (evt, id, value) => {
      evt.stopPropagation();
      dispatch(addCategory(id, value));
      dispatch(loadKultur());
    },
    addGroup: (evt, id, value) => {
      evt.stopPropagation();
      dispatch(addGroup(id));
      dispatch(loadKultur());
    },
  };
}
const withConnect = connect(
  null,
  mapDispatchToProps,
);

KulturListItemCard.propTypes = {
  item: PropTypes.object,
  classes: PropTypes.object,
};
export default compose(
  withConnect,
  withRouter,
  withStyles(styles),
)(KulturListItemCard);
