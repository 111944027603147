import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { Switch, Route, Link, withRouter,Redirect } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import _ from 'lodash';

import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { connect } from "react-redux";
import injectReducer from "../../utils/injectReducer";
import injectSaga from "../../utils/injectSaga";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import { CookiesProvider } from "react-cookie";
import Cookies from "universal-cookie";
import Hidden from "@material-ui/core/Hidden";
import Menu from "@material-ui/core/Menu";
import List from "@material-ui/core/List";
import HomePage from "../../containers/HomePage";
import FavoritePage from "../../containers/FavoritePage";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import EvenemangSingle from "../../containers/EvenemangSingle";
import KulturSingle from "../../containers/KulturSingle";
import RegisterPage from "../../containers/RegisterPage";
import OmOssPage from "../../containers/OmOssPage";
import PolicyPage from "../../containers/PolicyPage";
import GroupPage from "../../containers/GroupPage";
import UserProfiles from "../../containers/UserProfiles";
import UppgifterPage from "../../containers/UppgifterPage";

import ChangePasswordPage from "../../containers/ChangePasswordPage";
import Chip from "@material-ui/core/Chip";

import ResetPage from "../../containers/ResetPage";
import FyrkSingle from "../../containers/FyrkSingle";
import AddFyrk from "../../containers/AddFyrk";

import NyheterSingle from "../../containers/NyheterSingle";
import ProfileSingle from "../../containers/ProfileSingle";
import ProfileSingleEdit from "../../containers/ProfileSingleEdit";
import Nyheter from "../../containers/Nyheter";
import Fyrk from "../../containers/Fyrk";
import Kultur from "../../containers/Kultur";
import evenemang from "../../containers/EvenemangPage";

import {
  MuiThemeProvider,
  createTheme,
  withStyles,
} from "@material-ui/core/styles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Select from "react-select";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import NotFoundPage from "../../containers/NotFoundPage";
import Footer from "../../components/Footer";
import PropTypes, { func } from "prop-types";

import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import * as Autosuggest from "react-autosuggest";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Drawer from "@material-ui/core/Drawer";
import SearchIcon from "@mui/icons-material/Search";
import MenuIcon from "@mui/icons-material/Menu";

import FavoriteBorder from "@mui/icons-material/FavoriteBorder";

import Add from "@mui/icons-material/Add";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import {
  makeSelectCategoriesMainSelectedNames,
  makeSelectCategoriesMainSelected,
  makeSelectCategoriesMainSelectedNamesEvenemang,
  makeSelectCategoriesMainSelectedEvenemang,
  getLocationName,
  getCurrentEvent,
  getEventLocationName,
  makeSelectSearchFieldValue,
  makeSelectSearchFieldValueEvenemang,
  makeSelectStartTime,
  makeSelectSuggestions,
  makeSelectSuggestionsEvenemang,
  makeSelectEndTime,
  makeSelectPlatsSelected,
  makeSelectPlats,
  makeSelectAnchorEl,
  makeSelectGroupsEvenemang,
  makeSelectCategoriesEvenemang,
  makeSelectLocationsEvenemang,
  makeSelectCategoriesEvenemangSelected,
  makeSelectLocationsEvenemangSelected,
  makeSelectGroupsSelectedEvenemang,
  makeSelectGroupsSelected,
  makeSelectSearch,
  makeSelectSearchEvenemang,
  makeSelectGroups,
  makeToggleLeftDrawer,
  makeToggleRightDrawer,
  makeToggleRightMenu,
  makeSelectCategories,
  makeSelectCategoriesMainEvenemang,
  makeSelectValue,
  getScroll,
  makeSelectCategoriesMain,
  makeSelectSessionId,
  makeSelectUserId,
  isFullWidth,
  getTimeIndex,
  getReferencetime,
  getReferencetimeevent,
  getSelectedPeriodType,
  getTimeIndexEvent,
  getHasProfile,
  getProfile,
  getFavoriter,
  getKulturSkolan,
  getOpenDialog,
  getQOpenDialog,
  getRegions,
  selectLocation,
  selectRouter,
  getUserFyrkStatus,
} from "../../containers/App/selectors";
import DeleteIcon from "@mui/icons-material/Delete";

import Avatar from "@material-ui/core/Avatar";
import RandomImage from "../../components/RandomImage";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Logo from "../../images/kf_logo_2024.svg";
import Hjalp from "../../images/hjalp_button_KF.png";
import classNames from "classnames";
import LoginPage from "../../containers/LoginPage";
// import RegisterPage from '../../containers/RegisterPage';
import AddEvenemang from "../../containers/AddEvenemang";
import AddNyhet from "../../containers/AddNyhet";
// pick utils
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useStaticState, Calendar } from "@material-ui/pickers";

import saga from "./saga";
import { API_ROOT } from "../../api-config";
import {
  handleChange,
  setSuggestions,
  clearSuggestions,
  setSuggestionsEvenemang,
  clearSuggestionsEvenemang,
  handleSearchFieldValue,
  handleSearchFieldValueEvenemang,
  toggleLeftDrawer,
  toggleRightDrawer,
  loadCategories,
  loadNyheter,
  loadKultur,
  loadFyrk,
  hideDrawers,
  loadEvenemang,
  setScroll,
  handleChangeEvenemang,
  handleChangeEvenemangLocation,
  filterByDateEnd,
  filterByDateStart,
  filterSetReferenceTime,
  filterSetReferenceTimeEvent,
  filterByDateEndEvent,
  filterByDateStartEvent,
  handleChangePlats,
  handleSearch,
  handleSearchEvenemang,
  addCategory,
  removeCategory,
  addGroup,
  removeGroup,
  addKultur,
  removeKultur,
  addGroupEvenemang,
  removeGroupEvenemang,
  changeTimeIndex,
  changeTimeIndexEvent,
  logOut,
  removeCategoryEvenemang,
  addCategoryEvenemang,
  toggleRightMenu,
  deleteEvent,
  closeDialog,
  openDialog,
  QopenDialog,
  QcloseDialog,
  setCurrentEvent,
  setPeriodType,
} from "./actions";
import { PrivateRoute } from "../../components/PrivateRoute";
import reducer from "./reducer";
import GlobalStyle from "../../global-styles";
//import moment from "moment";
import MomentUtils from "@date-io/moment";
import "moment/locale/sv";
import moment from "moment/min/moment-with-locales";
import { useMatomo } from '@datapunt/matomo-tracker-react';
import A from '../../components/A';

const cookie = new Cookies();

moment.locale("sv");
const theme = createTheme({
  breakpoints: {
    keys: ["xs", "sm", "md", "lg", "xl", "xxl"],
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
      xxl: 2500,
    },
  },

  typography: {
    useNextVariants: true,
    fontFamily: ["Barlow"].join(","),
  },
  overrides: {
    MuiPickersDay: {
      day: {
        height: "27px",
        width: "27px",
      },
    },

    MuiListItem: {
      // Name of the component ⚛️ / style sheet
      divider: {
        // Name of the rule
        // borderBottom: "1px solid #f57d6c", // Some CSS
      },
    },
    MuiButton: {
      // Name of the component ⚛️ / style sheet
      label: {
        // Name of the rule
        fontSize: "14px", // Some CSS
        fontFamily: ["Barlow"].join(","),
        fontWeight: ["600"],
        textTransform: "uppercase",
        "@media (max-width: 960px)": {
          fontSize: "10px",
        }  
      },
    },
  },
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#f57d6c",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: "#f57d6c",
      main: "#f57d6c",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#f57d6c",
    },
    // error: will use the default color
  },
});
const styles = (theme) => ({
  appBar: {
    backgroundColor: "#ffffff",
    boxShadow: "none",
    transition: theme.transitions.create(
      ["margin", "width", "background-color"],
      {}
    ),
    // borderBottom: "2px solid #f57d6c",
    zIndex: "1100",
    [theme.breakpoints.down("sm")]: {
      //position: "fixed !important",
    },

    [theme.breakpoints.up("md")]: {
      // marginTop : '-80px  !important'
    },
    [theme.breakpoints.up("lg")]: {
      // marginTop : '-80px  !important'
    },
    "@media print": {
      display: "none",
    }
  },
  appbarclear: {
    transition: theme.transitions.create(
      ["margin", "width", "background-color"],
      {}
    ),
    backgroundColor: "#ffffff",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    borderRight: "1px solid #f57d6c",
  },
  drawerright: {
    width: drawerWidthRight,
    flexShrink: 0,
  },
  drawerPaperright: {
    width: drawerWidthRight,
    paddingTop: `${1}em`,
    // borderRight: "1px solid #f57d6c",
  },
  drawerFull: {
    width: drawerFull,
    marginTop: '44px',
    paddingTop: `${1}em`,
    // borderRight: "1px solid #f57d6c",
  },
  headermenuitem: {
    flexBasis: "auto",
    display: "block",
  },

  headermenuitemright: {
    flexGrow: 1,
    maxWidth: "100%",
    textAlign: "right",
    paddingRight: "1em",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
  root: {
    flexGrow: 1,
    backgroundColor: "#ffffff",
    minHeight: "0",
  },
  flex: {
    flex: 1,
  },
  logo: {
    width: `100%`,
    marginLeft: "-2px",
    textDecoration: "none",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      width: "130px",
    },
  },
  removeLeftMargin: {
    marginLeft: 0,
  },
  footerlogo: {
    width: `176px`,
    marginLeft: "1em",
    textDecoration: "none",
    padding: "0",
    [theme.breakpoints.down("sm")]: {
      width: "90px",
    }
  },
  logolink: {
    textAlign: "left",
    //paddingTop: "0.5em",
  },
  appFrame: {
    backgroundColor: "#ffffff",
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex",
    width: "100%",
  },
  daybox: {
    width: "10px",
    heigh: "10x",
  },
  suggestionsContainerOpen: {
    position: "absolute",
    zIndex: 1,
    marginTop: `${4}em`,
    right: 0,
    margin: `${1}em`,
    width: `${90}%`,
  },
  kontakta: {
    position: "fixed",
    bottom: 0,
    right: 0,
    zIndex: "9999",
    "@media print": {
      display: "none",
    },
  },
  suggestion: {
    display: "block",
    width: "100%",
  },
  chip: {
    verticalAlign: "middle",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
    }
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none",
    width: "100%",
  },
  menulink: {
    fontWeight: "bold",
    fontSize: "16px",
    display: "inline",
    "@media print": {
      display: "none",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    }
  },
  menulink2: {
    fontWeight: "bold",
    fontSize: "16px",
    "@media print": {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: "0.3em",
      fontSize: "12px",
    }
  },

  listmenulink: {
    fontSize: "16px",
    textTransform: "uppercase",
    fontFamily: "Barlow",
  },
  rightToolbar: {
    marginLeft: "auto",
    float: "right",
    flexGrow: 1,
  },

  textField: {
    margin: `${1}em`,
    width: `${90}%`,
  },

  listItem: {
    cursor: "pointer",
    paddingTop: "0.2em",
    paddingBottom: "0.2em",
  },
  listItemNormal: {
    marginLeft: "0.5em",
    color: "#BDBDBD",
  },
  listItemSelected: {
    marginLeft: "0.5em",
    color: "#f57d6c !important",
  },
  contentShift: {},
  menuButton: {
    marginLeft: 0,
    padding: 0,
    [theme.breakpoints.down("sm")]: {
      marginLeft: '0em',
      position: 'absolute',
      right: '1em',
    },
  },
  toolBar: {
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.down("xs")]: {
      marginTop: '0em',
    },
    [theme.breakpoints.down("md")]: {
      marginTop: '0',
      minHeight: '54px',
  },
  },
  picker: {
    marginTop: "1em",
    overflow: "hidden",
    maxWidth: "255px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  topmenu: {
    marginTop: 30,
  },
  mainwrapper: {
    maxWidth: "1600px",
    margin: "0 auto",
    width: "100%",
  },
  mainwrapperfull: {
    margin: "0 auto",
    width: "100%",
    paddingTop: "0",
    [theme.breakpoints.up("xs")]: {
      paddingTop: "7em",
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: "2em",
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: "5em",
    },
  },
  categoryListItem: {
    display: "block",
  },
  selector: {
    marginLeft: `${5}%`,
    width: `${90}%`,
    marginTop: "1em",
    marginBottom: "1em",
    zIndex: 1000,
  },
  locationtitle: {
    textTransform: "uppercase",
    color: "#F27A68",
  },
  chip: {
    [theme.breakpoints.down("sm")]: {
      margin: "0.5em",
      fontSize: "8px",
      maxWidth: "64px",
    },
  },
  morebutton: {
    display: 'inline-block',
    backgroundColor: '#000 !important',
    background: '#000',
    color: '#fff',
    marginLeft: '1em',
    borderRadius: 0,
    marginTop: '16px',
    padding: '4px 8px',
  },
  content: {
    [theme.breakpoints.down("sm")]: {
      // marginTop: `${6}em`,
      flexGrow: 1,
      backgroundColor: "#ffffff",
      padding: theme.spacing(3),
      paddingTop: "0",
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },

    [theme.breakpoints.up("md")]: {
      // marginTop: `${4}em`,
      flexGrow: 1,
      backgroundColor: "#ffffff",
      padding: theme.spacing(3),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    [theme.breakpoints.up("lg")]: {
      // marginTop: `${4}em`,
      flexGrow: 1,
      backgroundColor: "#ffffff",
      padding: theme.spacing(3),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  },
  menubar: {
    alignItems: "center",
  },
  contentFull: {
    marginLeft: 0,
    maxWidth: "none",
    flexGrow: 1,
    backgroundColor: "#ffffff",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  "contentShift-left": {
    marginLeft: 0,
  },
  "contentShift-right": {
    marginRight: 0,
  },
  paper: {
    padding: theme.spacing(2),
    position: "fixed",
  },
  checkBox: {
    width: `${35}px`,
    height: `${35}px`,
  },
  headerimagebutton: {
    padding: 0,
    margin: 0,
    "&:hover": {
      // you want this to be the same as the backgroundColor above
      backgroundColor: "transparent",
    },
  },
  selectedButton: {
    backgroundColor: "#f57d6c",
    color: "#fff",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    position: "relative",
  },
  input: {
    width: `${90}%`,
  },
  pageTitle: {
    fontSize: "48px",
    fontWeight: "300",
    marginBottom: "0.3em",
    marginTop: "0.3em",
  },
  menuselected: {
    textDecoration: "underline",
    color: "#000000",
  },
});

const drawerWidth = 300;
const drawerFull = '100%'
const drawerWidthRight = 200;
function renderInput(inputProps) {
  const { classes, ref, ...other } = inputProps;

  return (
    <TextField
      className={classes.textField}
      InputProps={{
        inputRef: ref,
        ...other,
      }}
    />
  );
}



function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.name, query);
  const parts = parse(suggestion.name, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part, index) =>
          part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 300 }}>
              {part.text}
            </span>
          ) : (
            <strong key={String(index)} style={{ fontWeight: 500 }}>
              {part.text}
            </strong>
          )
        )}
      </div>
    </MenuItem>
  );
}

function renderSuggestionsContainer(options) {
  const { containerProps, children } = options;

  return (
    <Paper {...containerProps} square>
      {children}
    </Paper>
  );
}

function getSuggestionValue(suggestion) {
  return suggestion;
}
/*
function getSuggestions(value) {
  const inputValue = value.value.trim().toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;
  const result =
    inputLength === 0
      ? []
      : suggestions.filter(suggestion => {
        const keep =
            count < 5 &&
            suggestion.label.toLowerCase().slice(0, inputLength) === inputValue;

          if (keep) {
          count += 1;
        }

        return keep;
        });
}
*/
/* EVENT */
function renderInputEvenemang(inputProps) {
  const { classes, ref, ...other } = inputProps;

  return (
    <TextField
      className={classes.textField}
      InputProps={{
        inputRef: ref,
        ...other,
      }}
    />
  );
}

function renderSuggestionEvenemang(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.name, query);
  const parts = parse(suggestion.name, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part, index) =>
          part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 300 }}>
              {part.text}
            </span>
          ) : (
            <strong key={String(index)} style={{ fontWeight: 500 }}>
              {part.text}
            </strong>
          )
        )}
      </div>
    </MenuItem>
  );
}

function renderSuggestionsContainerEvenemang(options) {
  const { containerProps, children } = options;

  return (
    <Paper {...containerProps} square>
      {children}
    </Paper>
  );
}

function getSuggestionValueEvenemang(suggestion) {
  return suggestion;
}
/* 
function getSuggestionsEvenemang(value) {
  const inputValue = value.value.trim().toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;
  const result =
    inputLength === 0
      ? []
      : suggestionsevenemang.filter(suggestion => {
        const keep =
            count < 5 &&
            suggestionsevenemang.label.toLowerCase().slice(0, inputLength) ===
              inputValue;

          if (keep) {
          count += 1;
        }

        return keep;
        });
}
*/
const AppWrapper = styled.div`
  display: flex;
  min-height: 100%;
  flex-direction: column;
  overflow: hidden;
`;




export class App extends React.Component {


  UNSAFE_componentWillMount() {
    this.props.loadCategories(this.props.value);
    this.image1 = RandomImage();
    this.nav = React.createRef();

  }



  componentDidMount() {
    if (this.props.routerlocation.pathname == "/") {
      window.addEventListener("scroll", this.handleScroll, true);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = (event) => {
    if (window.scrollY > 100) {
      this.props.setScroll(true);
    } else {
      this.props.setScroll(false);
    }
  };

  profileRedirect() {
    this.props.history.push(`/groups/`);
  }

  renderDay(day, selectedDate, dayInCurrentMonth, dayComponent) {
    if (selectedDate) {
      let enddate = selectedDate;
      let startdate = selectedDate;

      if (this.props.timeindexevent == 2) {
        enddate = selectedDate.clone().endOf("week");
        startdate = selectedDate.clone().startOf("week");
      }
      if (this.props.timeindexevent == 3) {
        enddate = selectedDate.clone().endOf("month");
        startdate = selectedDate.clone().startOf("month");
      }
      const style = {};
      if (day >= startdate && day <= enddate) {
        style.backgroundColor = "#f57d6c";
        style.color = "white";
      }
      return React.cloneElement(dayComponent, {
        style,
      });
    }
    return dayComponent;
  }

  renderDayNormal(day, selectedDate, dayInCurrentMonth, dayComponent) {
    let enddate = selectedDate;
    let startdate = selectedDate;

    if (this.props.timeindex == 2) {
      enddate = selectedDate.clone().endOf("week");
      startdate = selectedDate.clone().startOf("week");
    }
    if (this.props.timeindex == 3) {
      enddate = selectedDate.clone().endOf("month");
      startdate = selectedDate.clone().startOf("month");
    }
    const style = {};
    if (day >= startdate && day <= enddate) {
      style.backgroundColor = "#f57d6c";
      style.color = "white";
    }

    return React.cloneElement(dayComponent, {
      style,
    });
  }

  render() {
    const {
      searchfieldvalue,
      searchfieldvalueevenemang,
      classes,
      maincategories,
      maincategoriesevenemang,
      selectedmaincategories,
      selectedmaincategoriesevenemang,
      selectedlocationsevenemangname,
      selectedlocationsname,
      selectedgroups,
      searchevenemang,
      currentevent,
      anchorEl,
      timeindex,
      starttime,
      scroll,
      hasprofile,
      regions,
      timeindexevent,
      profile,
      kultur,
      width,
      opendialog,
      qopendialog,
      routerlocation,
      selectedperiodtype,
      fyrkstatus,
    } = this.props;

    let { referencetime } = this.props;
    let { referencetimeevent } = this.props;
    let fullwidth = false;
    let mainclass = classes.mainwrapper;
    if (
      this.props.routerlocation.pathname == "/nyheter" ||
      this.props.routerlocation.pathname == "/fyrk" ||
      this.props.routerlocation.pathname == "/" ||
      this.props.routerlocation.pathname == "/profiler" ||
      this.props.routerlocation.pathname == "/evenemang" ||
      this.props.routerlocation.pathname.includes("/evenemang/")
    ) {
      mainclass = classes.mainwrapperfull;
    }
    if (
      this.props.routerlocation.pathname == "/" ||
      this.props.routerlocation.pathname.includes("/evenemang/")
    ) {
      fullwidth = true;
    }
    let posterimages = false;
    let firstname = "";
    let lastname = "";
    let groupname = "";
    if (profile) {
      if (profile.posterimages && profile.posterimages.length > 0) {
        posterimages = profile.posterimages;
      }
      if (profile.firstname) {
        firstname = profile.firstname;
      }
      if (profile.lastname) {
        lastname = profile.lastname;
      }
      if (profile.groupname) {
        groupname = profile.groupname;
      }
    }
    if (referencetime == "") {
      referencetime = moment();
    }
    if (referencetimeevent == "") {
      referencetimeevent = moment();
    }

    let profileimageapi = false;
    if (posterimages) {
      profileimageapi = _.find(posterimages, { role: "profile" });
    }
    const kulturvalues = [
      { id: "I dagis", value: "d" },
      { id: "I skola", value: "s" },
      { id: "Repertoar Live", value: "l" },
    ];

    let profileimage = this.image1;

    if (profileimageapi) {
      profileimage = profileimageapi.thumbnail;
      profileimage = API_ROOT.replace(/\/$/, "") + profileimage;
      profileimage = profileimage.replace(/WxH/g, "100x100");
    }
    let { value } = this.props;
    if (!value) {
      value = [];
    }
    let { groups } = this.props;
    if (!groups) {
      groups = [];
    }
    let { selectedplats } = this.props;
    if (!selectedplats) {
      selectedplats = null;
    }
    let { selectedcategoriesevenemang } = this.props;
    if (!selectedcategoriesevenemang) {
      selectedcategoriesevenemang = [];
    }
    let { selectedgroupsevenemang } = this.props;
    if (!selectedgroupsevenemang) {
      selectedgroupsevenemang = [];
    }
    let { selectedlocationsevenemang } = this.props;
    if (!selectedlocationsevenemang) {
      selectedlocationsevenemang = null;
    }
    let { categories } = this.props;
    if (!categories) {
      categories = [];
    }
    let { plats } = this.props;
    if (!plats) {
      plats = [];
    }
    let { suggestions } = this.props;
    if (!suggestions) {
      suggestions = [];
    }
    let { suggestionsevenemang } = this.props;
    if (!suggestionsevenemang) {
      suggestionsevenemang = [];
    }
    let { categoriesevenemang } = this.props;
    if (!categoriesevenemang) {
      categoriesevenemang = [];
    }
    let { groupsevenemang } = this.props;
    if (!groupsevenemang) {
      groupsevenemang = [];
    }
    let { locationsevenemang } = this.props;
    if (!locationsevenemang) {
      locationsevenemang = [];
    }

    const open = this.props.leftmenu;

    const maincategorylist = maincategories.map((list) => {
      if (maincategories) {
        if (!selectedmaincategories.includes(list.id)) {
          return (
            <ListItem
              button
              className={classes.listItem}
              key={list.id}
              onClick={() => {
                this.props.addCategory(list.id, list.name);
              }}
            >
              <ListItemText
                classes={{ primary: classes.listItemNormal }}
                primary={list.name}
              />
            </ListItem>
          );
        }
        return (
          <ListItem
            button
            key={list.id}
            className={classes.listItem}
            onClick={() => {
              this.props.removeCategory(list.id);
            }}
          >
            <ListItemText
              classes={{ primary: classes.listItemSelected }}
              primary={list.name}
            />
          </ListItem>
        );
      }
    });
    const fyrkcategories = [
      { id: 147, name: "Barnkultur" },
      { id: 149, name: "Bildkonst" },
      { id: 172, name: "Forskning" },
      { id: 173, name: "Fortbildning" },
      { id: 193, name: "Information" },
      { id: 203, name: "Konst" },
      { id: 207, name: "Konstnärsresidens" },
      { id: 210, name: "Kultur" },
      { id: 215, name: "Litteratur" },
      { id: 271, name: "Utbildning" },
    ];
    const periodtypes = [
      { id: "VARIABLE,EXACT", name: "Exact" },
      { id: "CONTINUOUS", name: "Förtlopande" },
    ];
    const fyrkcategorylist = fyrkcategories.map((list) => {
      if (!selectedmaincategories.includes(list.id)) {
        return (
          <ListItem
            button
            className={classes.listItem}
            key={list.id}
            onClick={() => {
              this.props.addCategory(list.id, list.name);
            }}
          >
            <ListItemText
              classes={{ primary: classes.listItemNormal }}
              primary={list.name}
            />
          </ListItem>
        );
      }
      return (
        <ListItem
          button
          key={list.id}
          className={classes.listItem}
          onClick={() => {
            this.props.removeCategory(list.id);
          }}
        >
          <ListItemText
            classes={{ primary: classes.listItemSelected }}
            primary={list.name}
          />
        </ListItem>
      );
    });
    let kulturlist = null;
    if (this.props.routerlocation.pathname == "/profiler") {
      kulturlist = kulturvalues.map((list) => {
        if (!kultur.includes(list.value)) {
          return (
            <ListItem
              button
              className={classes.listItem}
              key={list.id}
              onClick={() => {
                this.props.addKultur(list.value);
              }}
            >
              <ListItemText
                classes={{ primary: classes.listItemNormal }}
                primary={list.id}
              />
            </ListItem>
          );
        }
        return (
          <ListItem
            button
            key={list.id}
            className={classes.listItem}
            onClick={() => {
              this.props.removeKultur(list.value);
            }}
          >
            <ListItemText
              classes={{ primary: classes.listItemSelected }}
              primary={list.id}
            />
          </ListItem>
        );
      });
    }
    // let kultursearch = null;
    // if (this.props.routerlocation.pathname == '/profiler') {
    //   kultursearch = (
    //     <div>
    //       {' '}
    //       <Typography
    //         style={{ color: '#666', marginLeft: '1em' }}
    //         variant="subtitle1"
    //         gutterBottom
    //       >
    //         Kultur
    //       </Typography>
    //       {kulturlist}
    //     </div>
    //   );
    // }

    const maincategorylistevents = maincategoriesevenemang.map((list) => {
      if (maincategoriesevenemang) {
        if (!selectedmaincategoriesevenemang.includes(list.id)) {
          return (
            <ListItem
              className={classes.listItem}
              key={list.id}
              onClick={() => {
                this.props.addCategoryMainEvent(list.id, list.name);
              }}
            >
              <ListItemText
                classes={{ primary: classes.listItemNormal }}
                primary={list.name}
              />
            </ListItem>
          );
        }
        return (
          <ListItem
            key={list.id}
            className={classes.listItem}
            onClick={() => {
              this.props.removeCategoryMainEvent(list.id);
            }}
          >
            <ListItemText
              classes={{ primary: classes.listItemSelected }}
              primary={list.name}
            />
          </ListItem>
        );
      }
    });
    const groupslist = groups.map((list) => {
      if (groups) {
        if (
          !selectedgroups.includes(list.id) &&
          !selectedcategoriesevenemang.includes(list.id)
        ) {
          return (
            <ListItem
              className={classes.listItem}
              key={list.id}
              onClick={() => {
                this.props.addGroup(list.id);
              }}
            >
              <ListItemText
                classes={{ primary: classes.listItemNormal }}
                primary={list.name}
              />
            </ListItem>
          );
        }
        return (
          <ListItem
            className={classes.listItem}
            key={list.id}
            onClick={() => {
              this.props.removeGroup(list.id);
            }}
          >
            <ListItemText
              classes={{ primary: classes.listItemSelected }}
              primary={list.name}
            />
          </ListItem>
        );
      }
    });
    const groupslistevenemang = groupsevenemang.map((list) => {
      if (groups) {
        if (
          !selectedgroupsevenemang.includes(list.id) &&
          !selectedmaincategoriesevenemang.includes(list.id)
        ) {
          return (
            <ListItem
              className={classes.listItem}
              key={list.id}
              onClick={() => {
                this.props.addGroupEvenemang(list.id);
              }}
            >
              <ListItemText
                classes={{ primary: classes.listItemNormal }}
                primary={list.name}
              />
            </ListItem>
          );
        }
        return (
          <ListItem
            className={classes.listItem}
            key={list.id}
            onClick={() => {
              this.props.removeGroupEvenemang(list.id);
            }}
          >
            <ListItemText
              classes={{ primary: classes.listItemSelected }}
              primary={list.name}
            />
          </ListItem>
        );
      }
    });
    let searchFilters = null;

    let timefilter = null;
    let searchToggleIcon = null;
    if (
      this.props.routerlocation.pathname == "/nyheter" ||
      this.props.routerlocation.pathname == "/fyrk" ||
      this.props.routerlocation.pathname == "/profiler" ||
      this.props.routerlocation.pathname == "/evenemang"
    ) {
      searchToggleIcon = (
        <Hidden mdUp>
          <IconButton
          fontSize='large'
            onClick={() => {
              this.props.toggleLeftDrawer();
            }}
          >
            {" "}
            {!open ? <SearchIcon /> : <ChevronLeftIcon />}{" Filtrera "}
          </IconButton>
        </Hidden>
      );
    }

    if (this.props.routerlocation.pathname == "/nyheter") {
      timefilter = (
        <div>
          <Typography
            style={{ color: "#000", marginLeft: "1em" }}
            variant="subtitle1"
            gutterBottom
          >
            När
          </Typography>

          <List>
            <ListItem
              className={classes.listItem}
              onClick={() => {
                this.props.filterByDate(1);
              }}
            >
              <ListItemText
                classes={{
                  primary:
                    timeindex == 1
                      ? classes.listItemSelected
                      : classes.listItemNormal,
                }}
                primary="I dag"
              />
            </ListItem>
            <ListItem
              className={classes.listItem}
              onClick={() => {
                this.props.filterByDate(2);
              }}
            >
              <ListItemText
                classes={{
                  primary:
                    timeindex == 2
                      ? classes.listItemSelected
                      : classes.listItemNormal,
                }}
                primary="Denna vecka"
              />
            </ListItem>
            <ListItem
              className={classes.listItem}
              onClick={() => {
                this.props.filterByDate(3);
              }}
            >
              <ListItemText
                classes={{
                  primary:
                    timeindex == 3
                      ? classes.listItemSelected
                      : classes.listItemNormal,
                }}
                primary="Denna månad"
              />
            </ListItem>
            <div className={classes.picker}>
              <Calendar
                date={referencetime}
                renderDay={this.renderDayNormal.bind(this)}
                onChange={this.props.setReferenceTime}
              />
            </div>
          </List>
        </div>
      );
    } else if (this.props.routerlocation.pathname == "/evenemang") {
      timefilter = (
        <div>
          <Typography
            style={{ color: "#0", marginLeft: "1em" }}
            variant="subtitle1"
            gutterBottom
          >
            När
          </Typography>
          <List>
            <ListItem
              className={classes.listItem}
              onClick={() => {
                this.props.filterByDateEvent(1);
              }}
            >
              <ListItemText
                classes={{
                  primary:
                    timeindexevent == 1
                      ? classes.listItemSelected
                      : classes.listItemNormal,
                }}
                primary="I dag"
              />
            </ListItem>
            <ListItem
              className={classes.listItem}
              onClick={() => {
                this.props.filterByDateEvent(2);
              }}
            >
              <ListItemText
                classes={{
                  primary:
                    timeindexevent == 2
                      ? classes.listItemSelected
                      : classes.listItemNormal,
                }}
                primary="Denna vecka"
              />
            </ListItem>
            <ListItem
              className={classes.listItem}
              onClick={() => {
                this.props.filterByDateEvent(3);
              }}
            >
              <ListItemText
                classes={{
                  primary:
                    timeindexevent == 3
                      ? classes.listItemSelected
                      : classes.listItemNormal,
                }}
                primary="Denna månad"
              />
            </ListItem>
            <div className={classes.picker}>
              <Calendar
                date={referencetimeevent}
                renderDay={this.renderDay.bind(this)}
                onChange={this.props.setReferenceTimeEvent}
              />
            </div>
          </List>
        </div>
      );
    }

    if (this.props.routerlocation.pathname == "/evenemang") {
      searchFilters = (
        <div>
          <Grid container>
            <Grid item xs={12}>
              <Autosuggest
                theme={{
                  container: classes.container,
                  suggestionsContainerOpen: classes.suggestionsContainerOpen,
                  suggestionsList: classes.suggestionsList,
                  suggestion: classes.suggestion,
                }}
                renderInputComponent={renderInputEvenemang}
                suggestions={suggestionsevenemang}
                onSuggestionsFetchRequested={
                  this.props.handleSuggestionsFetchRequestedEvenemang
                }
                onSuggestionsClearRequested={
                  this.props.handleSuggestionsClearRequestedEvenemang
                }
                onSuggestionSelected={
                  this.props.handleSuggestionSelectedEvenemang
                }
                renderSuggestionsContainer={renderSuggestionsContainerEvenemang}
                getSuggestionValue={getSuggestionValueEvenemang}
                renderSuggestion={renderSuggestionEvenemang}
                inputProps={{
                  classes,
                  placeholder: "Sök",
                  value: searchfieldvalueevenemang,
                  onChange: this.props.handleChangeAutoCompleteEvenemang,
                  onKeyUp: this.props.handleAutoSearchEvenemang,
                }}
              />
            </Grid>
          </Grid>
          <Typography
            style={{ color: "#000", marginLeft: "1em" }}
            variant="subtitle1"
            gutterBottom
          >
            Kategorier
          </Typography>
          <List>{maincategorylistevents}</List>

          <Typography
            style={{ color: "#000", marginLeft: "1em" }}
            variant="subtitle1"
            gutterBottom
          >
            Plats
          </Typography>

          <Select
            onChange={this.props.handleChangeEvenemangLocation}
            name="form-field-name"
            getOptionLabel={({ name }) => name}
            getOptionValue={({ id }) => id}
            value={selectedlocationsevenemang}
            options={locationsevenemang}
            className={classes.selector}
            placeholder="Välj"
          />

          {timefilter}
          <Typography
            style={{ color: "#000", marginLeft: "1em" }}
            variant="subtitle1"
            gutterBottom
          >
            För Vem
          </Typography>
          <List>{groupslistevenemang}</List>
        </div>
      );
    } else if (
      this.props.routerlocation.pathname == "/profiler" ||
      this.props.routerlocation.pathname == "/nyheter" ||
      this.props.routerlocation.pathname == "/fyrk"
    ) {
      searchFilters = (
        <div>
          <Autosuggest
            theme={{
              container: classes.container,
              suggestionsContainerOpen: classes.suggestionsContainerOpen,
              suggestionsList: classes.suggestionsList,
              suggestion: classes.suggestion,
            }}
            renderInputComponent={renderInput}
            suggestions={suggestions}
            onSuggestionsFetchRequested={
              this.props.handleSuggestionsFetchRequested
            }
            onSuggestionsClearRequested={
              this.props.handleSuggestionsClearRequested
            }
            onSuggestionSelected={this.props.handleSuggestionSelected}
            renderSuggestionsContainer={renderSuggestionsContainer}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={{
              classes,
              placeholder: "Sök",
              value: searchfieldvalue,
              onChange: this.props.handleChangeAutoComplete,
              onKeyPress: this.props.handleAutoSearch,
            }}
          />

          <Typography
            style={{ color: "#000", marginLeft: "1em" }}
            variant="subtitle1"
            gutterBottom
          >
            Kategorier
          </Typography>
          {this.props.routerlocation.pathname == "/fyrk" && (
            <>
              <List>{fyrkcategorylist}</List>
              <Select
                onChange={this.props.setPeriodType}
                name="form-field-name"
                className={classes.selector}
                getOptionLabel={({ name }) => name}
                getOptionValue={({ id }) => id}
                value={selectedperiodtype}
                options={periodtypes}
                placeholder="Perdiotyperna"
              />
              {/* <Box display={{ xs: 'none', sm: 'block' }} style={{padding: theme.spacing(2)}}>
          ProffBox information<br/>
          ProffBox information<br/>
          ProffBox information<br/>
          </Box> */}
            </>
          )}

          {this.props.routerlocation.pathname != "/fyrk" && (
            <>
              <List>{maincategorylist}</List>
              <Typography
                style={{ color: "#000", marginLeft: "1em" }}
                variant="subtitle1"
                gutterBottom
              >
                Plats
              </Typography>
              <Select
                onChange={this.props.handleChangePlats}
                name="form-field-name"
                className={classes.selector}
                getOptionLabel={({ name }) => name}
                getOptionValue={({ id }) => id}
                value={selectedplats}
                options={plats}
                placeholder="Välj"
              />{" "}
            </>
          )}
          {timefilter}
          {this.props.routerlocation.pathname != "/fyrk" && (
            <>
              <Typography
                style={{ color: "#000", marginLeft: "1em" }}
                variant="subtitle1"
                gutterBottom
              >
                För Vem
              </Typography>
              <List>{groupslist}</List>
            </>
          )}
        </div>
      );
    }
    let profileButton = null;
    let totalname = "";
    if (groupname != null && lastname == "") {
      totalname = groupname;
    } else if (lastname != "") {
      totalname = `${firstname} ${lastname}`;
    }
    if(isMobile) {
      totalname = totalname.substring(0, 3);
  }
  
    if (localStorage.getItem("token")) {
      profileButton = (
        <Button className={classes.menulink2} >
        <Chip
        fontSize='large'
          avatar={
            <Avatar src={profileimage} className={classNames(classes.avatar)} />
          }
          label={totalname}
          component="a"
          onClick={(evt) => {
            this.profileRedirect();
          }}
          className={classes.chip}
        />
        </Button>
      );
    } else {
      profileButton = (
        <Button className={classes.menulink} component={Link} to="/login">
          Logga in / REGISTRERA DIG
        </Button>
      );
    }

    let { rightdrawer } = this.props;
    // if (localStorage.getItem("token")) {
    //   rightdrawer = this.props.rightdrawer;
    // } else {
    //   rightdrawer = false;
    // }

    let loginButtonMobile = null;
    let menuButtonMobile = null;

    if (localStorage.getItem("token")) {
      loginButtonMobile = (
        <IconButton
          onClick={() => {
            this.props.toggleRightMenu();
          }}
          className={classes.menuButton}
          color="inherit"
          aria-label="Open drawer"
          style={{right:'-2em', top:'0.5em'}}
        >
          <MenuIcon fontSize='large' />
        </IconButton>
      );
    } else {
      loginButtonMobile = (
        <IconButton 
        component={Link} to="/login">
          <AccountCircle fontSize='large' />
        </IconButton>
      );
    }
    if (!localStorage.getItem("token")) {
      menuButtonMobile = (
          <IconButton
          style={{position:'absolute', right:'1em', top:'0.5em'}}  
            onClick={() => {
              this.props.toggleRightMenu();
            }}
            className={classes.menuButton}
            color="inherit"
            aria-label="Open mobile menu"

          >
            <MenuIcon  fontSize='large'             
            />
          </IconButton>
      );
    }


    let logoutButton = null;
    if (localStorage.getItem("token")) {
      logoutButton = (
        <Button
          className={classes.menulink}
          onClick={(evt) => {
            this.props.logOut();
          }}
        >
          Logga ut
        </Button>
      );
    }
    let logoutButtonMobile = null;

    if (localStorage.getItem("token")) {
      logoutButtonMobile = (
        <ListItem
          className={classes.listmenulink}
          onClick={(evt) => {
            this.props.logOut();
          }}
          button
        >
          Logga ut
        </ListItem>
      );
    }
    let searchColumn = null;
    let maincolumn = 12;
    if (
      this.props.routerlocation.pathname == "/profiler" ||
      this.props.routerlocation.pathname == "/nyheter" ||
      // this.props.routerlocation.pathname == '/fyrk' ||
      this.props.routerlocation.pathname == "/evenemang"
    ) {
      maincolumn = 10;

      searchColumn = (
        <Grid item xs={12} sm={12} md={2}>
          {searchFilters}
        </Grid>
      );
    }

    let fyrkmenu = "";
    if (localStorage.getItem("token") && hasprofile && fyrkstatus) {
      fyrkmenu = (
        <MenuItem
          onClick={(evt) => {
            this.props.toggleRightDrawer(evt);
          }}
          component={Link}
          to="/addfyrk"
        >
          Skapa fyrkpost
        </MenuItem>
      );
    }

    let useractionmenu = "";
    if (localStorage.getItem("token") && hasprofile) {
      useractionmenu = (
        <>
          <Button
             style={{minWidth:'32px'}}
            className={classes.menulink2}
            onClick={(evt) => {
              this.props.toggleRightDrawer(evt);
            }}
          >
            <Add fontSize='small'
          />
            <Hidden mdDown>SKAPA</Hidden>
          </Button>

          <Menu
            id="simple-menu"
            open={this.props.rightmenu}
            anchorEl={anchorEl}
            className={classes.topmenu}
            onClose={(evt) => this.props.toggleRightDrawer(evt)}
          >
            <MenuItem
              onClick={(evt) => {
                this.props.toggleRightDrawer(evt);
              }}
              component={Link}
              to="/addnyhet"
            >
              Skapa nyhet
            </MenuItem>
            <MenuItem
              onClick={(evt) => {
                this.props.toggleRightDrawer(evt);
              }}
              component={Link}
              to="/addevenemang"
            >
              Skapa evenemang
            </MenuItem>
            {fyrkmenu}
          </Menu>
        </>
      );
    }
    let likemenu = "";
      if (localStorage.getItem("token")) {
        likemenu = (
      <Button
        component={Link}
        to="/gillade"
        className={classNames({
          [classes.menulink2]: true,
          [classes.menuselected]:
            this.props.routerlocation.pathname == "/gillade",
        })}
        style={{minWidth:'32px'}}
      >
        <FavoriteBorder fontSize='large' />
      </Button>
    )
  };
let RightMenuDrawer = null;
if (localStorage.getItem("token")) {
  RightMenuDrawer = (
    <Grid container spacing={0}>
    <Grid item xs={12}>
      <ListItem
        className={classes.listmenulink}
        onClick={() => {
          this.props.toggleRightMenu();
        }}
        button
        component={Link}
        to="/evenemang"
      >
        EVENMANG
      </ListItem>
    </Grid>
    <Grid item xs={12}>
      <ListItem
        className={classes.listmenulink}
        onClick={() => {
          this.props.toggleRightMenu();
        }}
        button
        component={Link}
        to="/nyheter"
      >
        NYHETER
      </ListItem>
    </Grid>
    <Grid item xs={12}>
      <ListItem
        className={classes.listmenulink}
        onClick={() => {
          this.props.toggleRightMenu();
        }}
        button
        component={Link}
        to="/profiler"
      >
        AKTÖRER
      </ListItem>
    </Grid>
    <Grid item xs={12}>
      <ListItem
        className={classes.listmenulink}
        onClick={() => {
          this.props.toggleRightMenu();
        }}
        button
        component={Link}
        to="/fyrk"
      >
        FYRK.FI
      </ListItem>
    </Grid>
    <Grid item xs={12}>
      <ListItem
        className={classes.listmenulink}
        onClick={() => {
          this.props.toggleRightMenu();
        }}
        button
        component={Link}
        to="/omosspage"
      >
        KONTAKTA OSS 
      </ListItem>
    </Grid> 
    <Grid item xs={12}>
      {logoutButtonMobile}
    </Grid>
  </Grid>

);  
} else {
  RightMenuDrawer = (
    <Grid container spacing={2}>
    <Grid item xs={12}>
      <ListItem
        className={classes.listmenulink}
        onClick={() => {
          this.props.toggleRightMenu();
        }}
        button
        component={Link}
        to="/evenemang"
      >
        EVENMANG
      </ListItem>
    </Grid>
    <Grid item xs={12}>
      <ListItem
        className={classes.listmenulink}
        onClick={() => {
          this.props.toggleRightMenu();
        }}
        button
        component={Link}
        to="/nyheter"
      >
        NYHETER
      </ListItem>
    </Grid>
    <Grid item xs={12}>
      <ListItem
        className={classes.listmenulink}
        onClick={() => {
          this.props.toggleRightMenu();
        }}
        button
        component={Link}
        to="/profiler"
      >
        AKTÖRER
      </ListItem>
    </Grid>
    <Grid item xs={12}>
      <ListItem
        className={classes.listmenulink}
        onClick={() => {
          this.props.toggleRightMenu();
        }}
        button
        component={Link}
        to="/fyrk"
      >
        FYRK.FI
      </ListItem>
    </Grid>
    <Grid item xs={12}>
      <ListItem
        className={classes.listmenulink}
        onClick={() => {
          this.props.toggleRightMenu();
        }}
        button
        component={Link}
        to="/omoss"
      >
        KONTAKTA OSS 
      </ListItem>
    </Grid> 

  </Grid>
);
}

    let og_image = "";
    og_image = <meta property="og:image" content={profileimage} />;

    return (
      <MuiThemeProvider theme={theme}>
        <MuiPickersUtilsProvider
          libInstance={moment}
          utils={MomentUtils}
          locale="sv"
        >
          <CookiesProvider>
            <AppWrapper className={classes.root}>
              <Helmet
                titleTemplate="%s - Kulturforum"
                defaultTitle="Kulturforum - Svenskfinland"
              >
                <meta
                  name="Kulturforum - Svenskfinland"
                  content="Kulturforum - Svenskfinland"
                />
                {og_image}
              </Helmet>

              <div>
                <AppBar
                  ref={this.nav}
                  className={classNames(classes.appBar, {
                    [classes.appBarShift]: open,
                    [classes[`appBarShift-left`]]: open,
                    [classes.appbarclear]:
                      !scroll && this.props.routerlocation.pathname == "/",
                  })}
                  position="fixed"
                  elevation={0} 
                >
                  <Hidden xsDown>
                    <Toolbar className={classes.toolBar}>
                      <Grid className={classes.menubar} container spacing={0}>
                        <Grid
                          item
                          xs={12}
                          sm={2}
                          className={classes.headermenuitem}
                        >
                          <Button
                            disableRipple
                            classes={{ root: classes.headerimagebutton }}
                            className={classes.headerimagebutton}
                            component={Link}
                            to="/"
                          >
                            <img className={classes.logo} src={Logo} />
                          </Button>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={8}
                          md={6}
                          
                          className={classes.headermenuitem}
                        >
                          <Button
                            className={classNames({
                              [classes.menulink]: true,
                              [classes.menuselected]:
                                this.props.routerlocation.pathname ==
                                "/evenemang",
                            })}
                            component={Link}
                            to="/evenemang"
                          >
                            Evenemang
                          </Button>
                          <Button
                            className={classNames({
                              [classes.menulink]: true,
                              [classes.menuselected]:
                                this.props.routerlocation.pathname ==
                                "/nyheter",
                            })}
                            component={Link}
                            to="/nyheter"
                          >
                            Nyheter{" "}
                          </Button>
                          <Button
                            className={classNames({
                              [classes.menulink]: true,
                              [classes.menuselected]:
                                this.props.routerlocation.pathname ==
                                "/profiler",
                            })}
                            component={Link}
                            to="/profiler"
                          >
                            AKTÖRER{" "}
                          </Button>{" "}
                          <Hidden smDown>
                          <Button className={classes.menulink}
                          style={{minWidth:'32px'}}>
                            |{" "}
                          </Button>
                          </Hidden>
                          <Button
                            className={classNames({
                              [classes.menulink]: true,
                              [classes.menuselected]:
                              this.props.routerlocation.pathname == "/fyrk",
                            })}
                            component={Link}
                            to="/fyrk"
                          >
                            FYRK.FI{" "}
                          </Button>
                        </Grid>

                        <Grid
                          className={classes.headermenuitemright}
                          item
                          xs={12}
                          sm={6}
                          md={3}
                          
                        >
                          <Button className={classes.menulink} component={Link} to="/omoss">
                            KONTAKTA OSS
                          </Button>
                      {likemenu}
                      {useractionmenu}
                      {profileButton}
                      {logoutButton}
                        </Grid>
                      </Grid>
                    </Toolbar>
                  </Hidden>
                  
                  <Hidden smUp>
                    <Toolbar className={classes.toolBar} variant="dense" style={{minHeight:'48px'}}>
                        <a className={classes.logolink} href="/">
                          <img className={classes.logo} src={Logo} style={{marginRight:'2em'}} />
                        </a>
                        <div style={{position:'absolute', right:'4em',marginTop:'0'}}>
                
                            {likemenu}
                            {useractionmenu}
                            {profileButton}
                            {loginButtonMobile}
                        </div>
                           {menuButtonMobile}

                    <Grid
                      container
                      direction="row"
                      justifyContent="right"
                      alignItems="right"
                      spacing={2}
                    >
                      <Grid item>
                        <Button
                          className={classNames({
                            [classes.menulink]: true,
                            [classes.menuselected]:
                              this.props.routerlocation.pathname ==
                              "/evenemang",
                          })}
                          component={Link}
                          to="/evenemang"
                        >
                          Evenemang
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          className={classNames({
                            [classes.menulink]: true,
                            [classes.menuselected]:
                              this.props.routerlocation.pathname == "/nyheter",
                          })}
                          component={Link}
                          to="/nyheter"
                        >
                          Nyheter{" "}
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          className={classNames({
                            [classes.menulink]: true,
                            [classes.menuselected]:
                              this.props.routerlocation.pathname == "/profiler",
                          })}
                          component={Link}
                          to="/profiler"
                        >
                          AKTÖRER{" "}
                        </Button>
                      </Grid>{" "}
                      <Grid item>
                        <Button
                          className={classNames({
                            [classes.menulink]: true,
                            [classes.menuselected]:
                              this.props.routerlocation.pathname == "/fyrk",
                          })}
                          component={Link}
                          to="/fyrk"
                        >                      
                          Fyrk.fi{" "}
                        </Button>
                      </Grid>
                    </Grid>
                    </Toolbar>
                    <Grid container spacing={0} direction="row" justifyContent="left" alignItems="left">
                        <Grid item xs={12}>
                    {searchToggleIcon}
                    </Grid>
                    </Grid>
                    </Hidden>
                </AppBar>

                <div className={mainclass}>
                  <main
                    className={classNames({
                      [classes.content]: !fullwidth,
                      [classes.contentFull]: fullwidth,
                    })}
                  >
                    <Hidden mdUp>
                      <Drawer
                        onClose={() => {
                          this.props.hideDrawers();
                        }}
                        classes={{ paper: classes.drawerPaper }}
                        anchor="left"
                        open={this.props.leftmenu}
                      >
                        {searchFilters}
                      </Drawer>
                    </Hidden>
                    
                      <Drawer
                        classes={{ paper: classes.drawerFull }}
                        anchor="center"
                        open={rightdrawer}
                        onClose={() => {
                          this.props.toggleRightMenu();
                        }}
                      >
                        {RightMenuDrawer}
                      </Drawer>

                    <Grid
                      container
                      justifyContent="flex-start"
                      className={classes.root}
                      spacing={5}
                    >
                      <Hidden smDown>{searchColumn}</Hidden>
                      <Grid item xs={12} sm={12} md={maincolumn}>
                        
                        <Switch>
                        <Route
                          exact
                          path="/"
                          component={HomePage}
                      />

                          <Route
                            exact
                            path="/evenemang/:id"
                            component={EvenemangSingle}
                          />
                          <Route
                            exact
                            path="/evenemang/:id/edit"
                            component={AddEvenemang}
                          />
                          <Route
                            exact
                            path="/evenemang/:group/add"
                            component={AddEvenemang}
                          />
                          <Route
                            exact
                            path="/evenemang/:group/add/:type"
                            component={AddEvenemang}
                          />
                          <Route
                            exact
                            path="/fyrkpost/:id"
                            component={FyrkSingle}
                          />
                          <Route
                            exact
                            path="/fyrkpost/:id/edit"
                            component={AddFyrk}
                          />
                          <Route
                            exact
                            path="/fyrkpost/:group/add"
                            component={AddFyrk}
                          />
                          <Route
                            exact
                            path="/kultur/:id"
                            component={KulturSingle}
                          />
                          <Route
                            exact
                            path="/nyhet/:id"
                            component={NyheterSingle}
                          />
                          <Route
                            exact
                            path="/account/confirm-email/:token"
                            component={LoginPage}
                          />
                          <PrivateRoute
                            exact
                            path="/profile/edit"
                            component={ProfileSingleEdit}
                          />
                          <Route
                            exact
                            path="/profile/new"
                            component={ProfileSingleEdit}
                          />
                          <Route
                            exact
                            path="/profile"
                            component={ProfileSingle}
                          />
                          <Route
                            exact
                            path="/profile/:id"
                            component={ProfileSingle}
                          />
                          <PrivateRoute
                            exact
                            path="/profile/:id/edit"
                            component={ProfileSingleEdit}
                          />
                          <PrivateRoute
                            exact
                            path="/nyhet/:id/edit"
                            component={AddNyhet}
                          />
                          <Route path="/nyheter" component={Nyheter} />
                          <Route path="/groups" component={UserProfiles} />
                          <Route path="/uppgifter" component={UppgifterPage} />

                          <Route path="/nyheter" component={Nyheter} />
                          <Route path="/fyrk" component={Fyrk} />
                          <Route path="/group" component={GroupPage} />
                          <Route path="/evenemang" component={evenemang} />
                          <Route path="/profiler" component={Kultur} />
                          <Route path="/gillade" component={FavoritePage} />
                          <Route path="/login" component={LoginPage} />
                          <Route path="/register" component={RegisterPage} />
                          <Route path="/resetpassword" component={ResetPage} />
                          <Route path="/omoss" component={OmOssPage} />
                          <Route path="/policy" component={PolicyPage} />
                          <Route
                            path="/reset/:uid/:key"
                            component={ChangePasswordPage}
                          />
                          <PrivateRoute
                            path="/addevenemang"
                            component={AddEvenemang}
                          />
                          <PrivateRoute path="/addnyhet" component={AddNyhet} />
                          <PrivateRoute path="/addfyrk" component={AddFyrk} />

                          <Route path="" component={NotFoundPage} />
                        </Switch>
                      </Grid>
                    </Grid>
                  </main>
                </div>
              </div>
              <Footer />
              <a className={classes.kontakta} href="/omoss">
                <img className={classes.footerlogo} src={Hjalp} />
              </a>
              <Dialog
                open={opendialog}
                onClose={this.props.closeDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  Är du säker på att du vill ta bort evenemanget?{" "}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    När du tagit bort evenemanget kan det inte återställas.
                  </DialogContentText>
                </DialogContent>

                <DialogActions>
                  <Button onClick={this.props.closeDialog} color="primary">
                    Ångra
                  </Button>
                  <Button
                    onClick={(evt) => {
                      this.props.deleteEvent(evt, currentevent);
                    }}
                    variant="text"
                    size="small"
                    className={classes.deletebutton}
                  >
                    <DeleteIcon className={classes.leftIcon} />
                    TA BORT
                  </Button>
                </DialogActions>
              </Dialog>
              <GlobalStyle />
            </AppWrapper>
          </CookiesProvider>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    );
  }
}
App.propTypes = {
  leftmenu: PropTypes.bool,
  rightmenu: PropTypes.bool,
  rightdrawer: PropTypes.bool,
  toggleRightDrawer: PropTypes.func,
  toggleLeftDrawer: PropTypes.func,
  loadCategories: PropTypes.func,
  addCategory: PropTypes.func,
  removeCategory: PropTypes.func,
  handleChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.bool,
    PropTypes.object,
  ]),
};

export function mapDispatchToProps(dispatch) {
  return {
    toggleLeftDrawer: (evt) => {
      dispatch(toggleLeftDrawer());
    },
    toggleRightDrawer: (evt) => {
      dispatch(toggleRightDrawer(evt.currentTarget));
    },
    toggleRightMenu: (evt) => {
      dispatch(toggleRightMenu());
    },
    hideDrawers: (evt) => {
      dispatch(hideDrawers());
    },
    loadCategories: (value) => dispatch(loadCategories(value)),
    addCategory: (id, name) => {
      dispatch(addCategory(id, name));
      dispatch(loadNyheter());
      dispatch(loadKultur());
      dispatch(loadFyrk());
    },
    removeCategory: (value) => {
      dispatch(removeCategory(value));
      dispatch(loadNyheter());
      dispatch(loadKultur());
      dispatch(loadFyrk());
    },
    addCategoryMainEvent: (id, name) => {
      dispatch(addCategoryEvenemang(id, name));
      dispatch(loadEvenemang());
    },
    setPeriodType: (value) => {
      dispatch(setPeriodType(value));
      dispatch(loadFyrk());
    },
    removeCategoryMainEvent: (value) => {
      dispatch(removeCategoryEvenemang(value));
      dispatch(loadEvenemang());
    },
    setReferenceTime: (value) => {
      dispatch(filterSetReferenceTime(value));
      dispatch(changeTimeIndex(1));
      dispatch(loadNyheter());
      dispatch(loadKultur());
      //event.stopPropagation();
    },
    setReferenceTimeEvent: (value) => {
      dispatch(filterSetReferenceTimeEvent(value));
      // dispatch(changeTimeIndexEvent(1));
      dispatch(loadEvenemang());

      // event.stopPropagation();
    },
    filterByDateRangeStart: (value) => {
      dispatch(filterByDateStart(value));
      dispatch(loadNyheter());
      dispatch(loadKultur());
      // event.stopPropagation();
    },
    filterByDateRangeEnd: (event, date) => {
      const enddate = new Date(event.target.value);
      const endstring = enddate.toISOString();
      dispatch(changeTimeIndex(0));
      dispatch(filterByDateEnd(endstring));
      dispatch(loadNyheter());
      dispatch(loadKultur());
      // event.stopPropagation();
    },
    filterByDate: (index) => {
      // dispatch(filterByDate(value1, value2));
      dispatch(changeTimeIndex(index));
      dispatch(loadNyheter());
      dispatch(loadKultur());
    },
    filterByDateRangeStartEvent: (value) => {
      dispatch(filterByDateStartEvent(value));

      // const startdate = new Date(event.target.value);
      // const startstring = startdate.toISOString();
      // dispatch(changeTimeIndexEvent(0));
      // dispatch(filterByDateStartEvent(startstring));
      dispatch(loadEvenemang());
    },
    filterByDateRangeEndEvent: (event, date) => {
      const enddate = new Date(event.target.value);
      const endstring = enddate.toISOString();
      dispatch(filterByDateEndEvent(endstring));
      dispatch(changeTimeIndexEvent(0));
      dispatch(loadEvenemang());
    },
    filterByDateEvent: (index) => {
      // dispatch(filterByDateEvent(value1, value2));
      dispatch(changeTimeIndexEvent(index));
      dispatch(loadEvenemang());
    },
    addKultur: (value) => {
      dispatch(addKultur(value));
      dispatch(loadKultur());
    },
    removeKultur: (value) => {
      dispatch(removeKultur(value));
      dispatch(loadKultur());
    },
    deleteEvent: (evt, value) => {
      dispatch(deleteEvent(value));
      dispatch(setCurrentEvent(false));
      dispatch(closeDialog());
      dispatch(loadEvenemang());
    },
    addGroup: (value) => {
      dispatch(addGroup(value));
      dispatch(loadNyheter());
      dispatch(loadKultur());
      dispatch(loadFyrk());
    },
    removeGroup: (value) => {
      dispatch(removeGroup(value));
      dispatch(loadNyheter());
      dispatch(loadKultur());
      dispatch(loadFyrk());
    },
    addGroupEvenemang: (value) => {
      dispatch(addGroupEvenemang(value));
      dispatch(loadEvenemang());
    },
    removeGroupEvenemang: (value) => {
      dispatch(removeGroupEvenemang(value));
      dispatch(loadEvenemang());
    },
    handleChange: (value) => {
      dispatch(handleChange(value));
      dispatch(loadNyheter());
      dispatch(loadKultur());
      dispatch(loadFyrk());
    },
    handleChangePlats: (value) => {
      dispatch(handleChangePlats(value));
      dispatch(loadNyheter());
      dispatch(loadKultur());
      dispatch(loadFyrk());
    },
    handleChangeEvenemang: (value) => {
      dispatch(handleChangeEvenemang(value));
      dispatch(loadEvenemang());
    },
    handleChangeEvenemangLocation: (value) => {
      dispatch(handleChangeEvenemangLocation(value));
      dispatch(loadEvenemang());
    },
    handleSearch: (event) => {
      dispatch(handleSearch(event.target.value));
      if (event.target.value.length > 2) {
        dispatch(loadNyheter());
        dispatch(loadKultur());
        dispatch(loadFyrk());
      }
    },
    handleSearchEvenemang: (event) => {
      dispatch(handleSearchEvenemang(event.target.value));
      if (event.target.value.length > 2) {
        dispatch(loadEvenemang());
      }
    },
    handleSuggestionsFetchRequested: (value) => {
      dispatch(setSuggestions(value));
    },
    handleSuggestionsClearRequested: () => {
      dispatch(clearSuggestions(""));
    },
    handleChangeAutoComplete: (event, value) => {
      dispatch(handleSearchFieldValue(event.target.value));
    },
    logOut: () => {
      dispatch(logOut());
    },
    handleSuggestionSelected: (event, newValue) => {
      dispatch(handleSearchFieldValue(""));
      dispatch(handleSearch(""));
      dispatch(
        addCategory(newValue.suggestionValue.id, newValue.suggestionValue.name)
      );
      dispatch(loadNyheter());
      dispatch(loadKultur());
      dispatch(loadFyrk());
    },
    handleAutoSearch: (event) => {
      if (event.key === "Enter") {
        dispatch(removeCategory("all"));
        dispatch(handleSearch(event.target.value));
        // dispatch(addCategory(newValue.suggestionValue.id));
        dispatch(loadNyheter());
        dispatch(loadKultur());
        dispatch(loadFyrk());
      }
    },
    openDialog: () => {
      dispatch(openDialog());
    },
    closeDialog: () => {
      dispatch(closeDialog());
    },
    QopenDialog: () => {
      // dispatch(QopenDialog());
      window.open("https://forms.gle/5q35eSKFMbM5i8Uc7", "_blank");
      dispatch(QcloseDialog());
    },
    QcloseDialog: () => {
      cookie.set("Q", 1, { path: "/" });
      dispatch(QcloseDialog());
    },
    setScroll: (value) => {
      dispatch(setScroll(value));
    },
    handleSuggestionsFetchRequestedEvenemang: (value) => {
      dispatch(setSuggestionsEvenemang(value));
    },
    handleSuggestionsClearRequestedEvenemang: () => {
      dispatch(clearSuggestionsEvenemang(""));
    },
    handleChangeAutoCompleteEvenemang: (event, value) => {
      dispatch(handleSearchFieldValueEvenemang(event.target.value));
      /*    if(event.target.value.length == 0) {
        dispatch(handleSearchFieldValueEvenemang(''));
        dispatch(handleSearchEvenemang(''));
        dispatch(loadEvenemang());
      } */
    },
    handleSuggestionSelectedEvenemang: (event, newValue) => {
      dispatch(handleSearchFieldValueEvenemang(""));
      dispatch(handleSearchEvenemang(""));
      dispatch(
        addCategoryEvenemang(
          newValue.suggestionValue.id,
          newValue.suggestionValue.name
        )
      );
      dispatch(loadEvenemang());
    },
    handleAutoSearchEvenemang: (event) => {
      if (event.key === "Enter") {
        dispatch(removeCategoryEvenemang("all"));
        dispatch(handleSearchEvenemang(event.target.value));
        // dispatch(addCategory(newValue.suggestionValue.id));
        dispatch(loadEvenemang());
      }
      if (event.key === "Backspace" && event.target.value == "") {
        dispatch(handleSearchEvenemang(""));
        dispatch(loadEvenemang());
      }
    },
  };
}

const mapStateToProps = createStructuredSelector({
  leftmenu: makeToggleLeftDrawer(),
  rightmenu: makeToggleRightDrawer(),
  rightdrawer: makeToggleRightMenu(),
  categories: makeSelectCategories(),
  categoriesevenemang: makeSelectCategoriesEvenemang(),
  maincategoriesevenemang: makeSelectCategoriesMainEvenemang(),
  groupsevenemang: makeSelectGroupsEvenemang(),
  locationsevenemang: makeSelectLocationsEvenemang(),
  maincategories: makeSelectCategoriesMain(),
  value: makeSelectValue(),
  regions: getRegions(),
  selectedmaincategoriesevenemang: makeSelectCategoriesMainSelectedEvenemang(),
  selectedmaincategoriesnamesevenemang:
    makeSelectCategoriesMainSelectedNamesEvenemang(),
  selectedmaincategories: makeSelectCategoriesMainSelected(),
  selectedmaincategoriesnames: makeSelectCategoriesMainSelectedNames(),
  selectedcategoriesevenemang: makeSelectCategoriesEvenemangSelected(),
  selectedlocationsevenemang: makeSelectLocationsEvenemangSelected(),
  selectedlocationsevenemangname: getEventLocationName(),
  selectedlocationsname: getLocationName(),
  selectedgroupsevenemang: makeSelectGroupsSelectedEvenemang(),
  search: makeSelectSearch(),
  searchevenemang: makeSelectSearchEvenemang(),
  groups: makeSelectGroups(),
  selectedgroups: makeSelectGroupsSelected(),
  plats: makeSelectPlats(),
  starttime: makeSelectStartTime(),
  endtime: makeSelectEndTime(),
  selectedplats: makeSelectPlatsSelected(),
  suggestions: makeSelectSuggestions(),
  suggestionsevenemang: makeSelectSuggestionsEvenemang(),
  searchfieldvalue: makeSelectSearchFieldValue(),
  searchfieldvalueevenemang: makeSelectSearchFieldValueEvenemang(),
  sessionId: makeSelectSessionId(),
  userid: makeSelectUserId(),
  anchorEl: makeSelectAnchorEl(),
  fullwidth: isFullWidth(),
  timeindex: getTimeIndex(),
  hasprofile: getHasProfile(),
  timeindexevent: getTimeIndexEvent(),
  profile: getProfile(),
  referencetime: getReferencetime(),
  referencetimeevent: getReferencetimeevent(),
  favoriter: getFavoriter(),
  kultur: getKulturSkolan(),
  opendialog: getOpenDialog(),
  qopendialog: getQOpenDialog(),
  currentevent: getCurrentEvent(),
  scroll: getScroll(),
  location: makeSelectLocationsEvenemang(),
  routerlocation: selectLocation(),
  selectedperiodtype: getSelectedPeriodType(),
  fyrkstatus: getUserFyrkStatus(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({ key: "app", reducer });
const withSaga = injectSaga({ key: "app", saga });

const AppWithMatomo = (props) => {
  const { trackPageView } = useMatomo();

  React.useEffect(() => {
    trackPageView({});
  }, []);

  return <App {...props} />;
};



export default compose(
  withSaga,
  withRouter,
  withReducer,
  withConnect,
  withWidth(),
  withStyles(styles)
)(AppWithMatomo);
