/*
 * HomePage
 *
 * This is the first thing users see of our App, at the '/' route
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import injectReducer from '../../utils/injectReducer';
import injectSaga from '../../utils/injectSaga';
import { addCategory,loadKultur,addGroup} from '../App/actions';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';

import H2 from '../../components/H2';
import H1 from '../../components/H1';
import NyheterList from '../../components/NyheterList';
import FyrkList from '../../components/FyrkList';
import Container from '@material-ui/core/Container';

import { push } from 'connected-react-router';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { getOwnFyrk,makeSelectProfile, getOwnEvents,getOwnNews,getOwnEventsPast,getSimilar } from './selectors';
import CenteredSection from './CenteredSection';
import KulturListItem from '../../containers/KulturListItem';
import FyrkListItem from '../../containers/FyrkListItem';

import { withStyles } from '@material-ui/core/styles';
import ProfileSingleCard from '../../containers/ProfileSingleCard';


import RandomImage from '../../components/RandomImage';
import {
  makeSelectUserId,
  makeSelectCurrentUser,
} from '../../containers/App/selectors';
import 'moment/locale/sv';
import reducer from './reducer';
import saga from './saga';
import { deleteProfile } from '../App/actions';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { loadProfileSingle } from './actions';
import { API_ROOT } from '../../api-config';
const styles = {
  card: {
    marginTop: '1em',
  },
  cardcontent: {
    marginLeft: '3em',
    marginRight: '3em',
  },
  profilesingle: {
  paddingTop: '8em',}
  ,
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  iconcolor: {
    marginRight: '0.5em',
    color: '#666',
  },
  title: {
    textAlign: 'left',
    paddingLeft: '1em',
  },
  bigAvatar: {
    width: 150,
    height: 150,
    marginTop: 40,
  },
  avatar: {
    margin: 10,
  },
  categorylink: {
    cursor: 'pointer',
    color: '#f57d6c',
    textDecoration: 'none',
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
  },
};

export class ProfileSingle extends React.PureComponent {
  UNSAFE_componentWillMount() {
    const singleid = this.props.match.params.id;
    if (singleid) {
      this.props.onSubmitForm(singleid);
    } else {
      this.props.onSubmitForm(0);
    }

  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.id == this.props.match.params.id) {
      // window.scrollTo(0, 0);
    } else {
      const singleid = nextProps.match.params.id;

      if (singleid) {
        this.props.onSubmitForm(singleid);
      } else {
        this.props.onSubmitForm(0);
      }
      // window.scrollTo(0, 0);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  handleBack() {
    this.props.history.push('/');
  }

  handleItemClickEdit(evt, id) {
    evt.stopPropagation();
    this.props.history.push(`/profile/${id}/edit`);
  }

  render() {


    const {
      classes,
      profile = {},
      locale,
      events,
      similar,
      userid,
      user,
      nyheter,
      eventspast,
      fyrk
    } = this.props;
 


    if(profile) {
    return (
      <Container maxWidth="md"  className='profilesingle' style={{paddingTop: '8em'}}>
        <ProfileSingleCard
          profile={profile}
          locale={locale}
          events={events}
          eventlist={true}
          nyheter={nyheter}
          fyrk={fyrk}
          similar={similar}
          eventspast={eventspast}
          user={user}
          userid={userid}
          deleteProfile={this.props.deleteProfile}

        />
      </Container>
    )
  } 
  return null;
  }
}

ProfileSingle.propTypes = {
  evenemang: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
};

const mapStateToProps = createStructuredSelector({
  profile: makeSelectProfile(),
  events: getOwnEvents(),
  eventspast: getOwnEventsPast(),
  nyheter: getOwnNews(),
  // profiles: makeSelectKultur(),
  similar: getSimilar(),
  userid: makeSelectUserId(),
  user: makeSelectCurrentUser(),
  fyrk : getOwnFyrk()
});

export function mapDispatchToProps(dispatch) {
  return {
    onSubmitForm: singleid => {
      dispatch(loadProfileSingle(singleid));
    },
    deleteProfile: (evt, id) => {
      dispatch(deleteProfile(id));
      evt.stopPropagation();
    },
    addCategory: (evt, id, value) => {
      evt.stopPropagation();
      dispatch(addCategory(id, value));
      dispatch(loadKultur());
      dispatch(push(`/profiler`));
    },
    addGroup: (evt, id, value) => {
      evt.stopPropagation();
      dispatch(addGroup(id));
      dispatch(loadKultur());
      dispatch(push(`/profiler`));
    },
  };
}
const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);
const withReducer = injectReducer({ key: 'profilesingle', reducer });
const withSaga = injectSaga({ key: 'profilesingle', saga });

export default compose(
  withReducer,
  withSaga,
  withRouter,
  withConnect,
  withStyles(styles),
)(ProfileSingle);
