/*
 * ProfilesingleCard
 *
 * This is the first thing users see of our App, at the '/' route
 */

import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import { makeSelectLocale } from "../LanguageProvider/selectors";
import messages from "./messages";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import H2 from "../../components/H2";
import H1 from "../../components/H1";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import EvenemangList from "../../components/EvenemangList";
import Avatar from "@material-ui/core/Avatar";
import EvenemangListItem from "../../containers/EvenemangListItem";
import CenteredSection from "./CenteredSection";
import KulturListItem from "../../containers/KulturListItem";
import FyrkListItem from "../../containers/FyrkListItem";
import NyheterList from "../../components/NyheterList";
import FyrkList from "../../components/FyrkList";
import { getUserFyrkStatus } from "../App/selectors";
import RandomImage from "../../components/RandomImage";
import "moment/locale/sv";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { API_ROOT } from "../../api-config";
import Place from "../../images/place.svg";
import Time from "../../images/time.svg";
import Gmap from "../../images/google.svg";
import { createStructuredSelector } from "reselect";

const styles = {
  card: {
    marginTop: "10em",
  },
  cardcontent: {
    marginLeft: "3em",
    marginRight: "3em",
  },

  Url: {
    color: "#000000",
    textDecoration: "underline",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
    "@media print": {
      display: "none",
    }
  },
  iconcolor: {
    marginRight: "0.5em",
    color: "#666",
  },
  title: {
    textAlign: "left",
    paddingLeft: "1em",
  },
  bigAvatar: {
    width: 150,
    height: 150,
    marginTop: 40,
  },
  avatar: {
    margin: 10,
  },
  categorylink: {
    cursor: "pointer",
    color: "#f57d6c",
    textDecoration: "none",
  },
  row: {
    display: "flex",
    justifyContent: "center",
  },
};
export class ProfileSingleCard extends React.PureComponent {
  UNSAFE_componentWillReceiveProps(nextProps) {}

  UNSAFE_componentWillMount() {
    this.fyrkstatus = React.createRef();

    this.image1 = RandomImage();
    this.image2 = RandomImage();
  }
  handleItemClickEdit(evt, id) {
    evt.stopPropagation();
    this.props.history.push(`/profile/${id}/edit`);
  }

  render() {
    const kulturvalues = [];
    kulturvalues.d = "I dagis";
    kulturvalues.s = "I skola";
    kulturvalues.l = "Repertoar Live";
    kulturvalues.NONE = "";

    const {
      classes,
      profile = {},
      userid,
      user,
      nyheter,
      events,
      eventspast,
      fyrk,
      similar,
      deleteProfile,
      fyrkstatus,
    } = this.props;

    const {
      firstname,
      lastname,
      description,
      groups,
      groupname,
      categories,
      postercategories,
      posterimages,
      kultur,
      related,
      regions,
      urls,
    } = profile;

    //const completeprofile = profile.profile;

    const completeprofile = true;

    const loading = false;
    const error = false;
    const nyhetListProps = {
      loading,
      error,
      nyheter,
      userid,
      user,
    };
    const fyrkListProps = {
      loading,
      error,
      fyrk,
      userid,
      user,
    };
    const headerimageapi = _.find(posterimages, { role: "header" });
    const profileimageapi = _.find(posterimages, { role: "profile" });
    let headerimage = this.image1;
    let profileimage = this.image2;

    let groupslist = null;
    if (groups && groups.length > 0) {
      groupslist = groups.map((list) => (
        <a
          key={list.id}
          id={list.id}
          className={classes.categorylink}
          onClick={(evt) => {
            this.props.addGroup(evt, list.id, list.name);
          }}
        >
          #{list.name}{" "}
        </a>
      ));
    }
    let Nyhettitle = "";
    if (nyheter && nyheter.length > 0) {
      Nyhettitle = <H2>Nyheter</H2>;
    }
    let Fyrktitle = "";
    if (fyrk && fyrk.length > 0) {
      Fyrktitle = <H2>Fyrk</H2>;
    }
    let categorieslist = null;
    if (categories && categories.length > 0) {
      categorieslist = categories.map((list) => (
        <a
          key={list.id}
          className={classes.categorylink}
          onClick={(evt) => {
            this.props.addCategory(evt, list.id, list.name);
          }}
        >
          #{list.name}{" "}
        </a>
      ));
    }

    let postercategorieslist = null;
    if (postercategories && postercategories.length > 0) {
      postercategorieslist = postercategories.map((list) => (
        <a
          key={list.id}
          className={classes.categorylink}
          onClick={(evt) => {
            this.props.addCategory(evt, list.id, list.name);
          }}
        >
          #{list.name}{" "}
        </a>
      ));
    }

    let regionslist = null;
    if (regions && regions.length > 0) {
      regionslist = regions.map((list) => (
        <a key={list.id} className={classes.categorylink}>
          #{list.name}{" "}
        </a>
      ));
    }
    if (headerimageapi) {
      headerimage = headerimageapi.thumbnail;
      headerimage = API_ROOT.replace(/\/$/, "") + headerimage;
      headerimage = headerimage.replace(/WxH/g, "1000x1000");
    }
    if (profileimageapi) {
      profileimage = profileimageapi.thumbnail;
      profileimage = API_ROOT.replace(/\/$/, "") + profileimage;
      profileimage = profileimage.replace(/WxH/g, "440x440");
    }
    let evenemanglist = null;
    if (events && events.length > 0) {
      evenemanglist = events.map((card, i) => (
        <EvenemangListItem item={card} key={card.id} />
      ));
    } else {
    }
    let evenemanglistsection = null;
    if (events && events.length > 0 && completeprofile) {
      evenemanglistsection = (
        <div>
          <H2>Evenemang</H2>
          <CenteredSection>{evenemanglist}</CenteredSection>
        </div>
      );
    }
    let evenemanglistpast = null;
    if (eventspast && eventspast.length > 0) {
      evenemanglistpast = eventspast.map((card, i) => (
        <EvenemangListItem item={card} key={card.id} />
      ));
    } else {
    }
    let evenemanglistpastsection = null;
    if (eventspast && eventspast.length > 0 && completeprofile) {
      evenemanglistpastsection = (
        <div>
          <H2>Tidigare evenemang</H2>
          <CenteredSection>{evenemanglistpast}</CenteredSection>
        </div>
      );
    }
    let Url = "";
    if (urls && urls.length > 0) {
      Url = urls[0].url;
    }

    let editbutton = "";
    if (user) {
      if (profile.user == userid || user.is_staff) {
        editbutton = (
          <Button
            onClick={(evt) => {
              this.handleItemClickEdit(evt, profile.id);
            }}
            variant="text"
            size="small"
            className={classes.editbutton}
          >
            <EditIcon className={classes.leftIcon} />
          </Button>
        );
      }
    }

    let deletebutton = "";
    if (user) {
      if (profile.user == userid || user.is_staff) {
        deletebutton = (
          <Button
            onClick={(evt) => {
              deleteProfile(evt, profile.id);
            }}
            variant="text"
            size="small"
            className={classes.deletebutton}
          >
            <DeleteIcon className={classes.leftIcon} />
          </Button>
        );
      }
    }
    let linkList = null;
    if (related && related.length > 0) {
      linkList = related.map((card, i) => (
        <KulturListItem item={card} key={card.id} />
      ));
    }
    // let linkListSection = "";
    // if (related && related.length > 0 && completeprofile) {
    //   linkListSection = (
    //     <div>
    //       <H2>Länkade profiler </H2>
    //       <CenteredSection>{linkList}</CenteredSection>
    //     </div>
    //   );
    // }

    let profileList = null;
    if (similar && similar.length > 0) {
      profileList = similar.map((card, i) => (
        <KulturListItem item={card} key={card.id} />
      ));
    } else {
      profileList = <p>no items</p>;
    }

    let profileListSection = "";
    if (similar && similar.length > 0 && completeprofile) {
      profileListSection = (
        <div>
          <H2>Liknande Profiler</H2>
          <CenteredSection>{profileList}</CenteredSection>
        </div>
      );
    }

    let kulturList = null;
    const kulturarray = [];
    if (kultur && kultur.length > 0) {
      kultur.map((card, i) => kulturarray.push(kulturvalues[card]));
      if (kulturvalues[0] != "NONE") {
        kulturList = kulturarray.join(", ", kulturarray);
      }
    }
    return (
      <Grid container spacing={6} className="profile">
        {completeprofile ||
          (!this.fyrkstatus && (
            <Grid item xs={12} md={12}>
              <CardMedia className={classes.media} image={headerimage} />
              {deletebutton}
              {editbutton}
            </Grid>
          ))}
        {!completeprofile && (
          <Grid item xs={12} md={12}>
            {deletebutton}
            {editbutton}
          </Grid>
        )}

        <Grid item xs={12} md={12}>
          {completeprofile && (
            <div className={classes.row}>
              <Avatar
                src={profileimage}
                className={classNames(classes.avatar, classes.bigAvatar)}
              />
            </div>
          )}
          <CenteredSection>
            {completeprofile && (
              <div>
                <H1>
                  {firstname} {lastname}
                </H1>
                <H2>{groupname}</H2>
                {/* <p>{postercategorieslist} </p> */}
                {/* <p>{groupslist}</p> */}
                <p><a className={classes.Url} 
                href='{Url}'>Gå till aktörens webbsida</a></p>
              </div>
            )}

            {!completeprofile && (
              <div>
                {/* <h4>AV KATEGORIER</h4>
                <p>{categorieslist}</p>
                <h4>AV PLATSER</h4>
                {regionslist}
                <h4>FÖR GRUPPER</h4>
                {groupslist} */}
              </div>
            )}
            <p dangerouslySetInnerHTML={{ __html: description }} />
          </CenteredSection>
          {/* {linkListSection} */}
          {evenemanglistsection}
          {evenemanglistpastsection}
          {Nyhettitle}
          {nyheter && (
            <CenteredSection>
              <NyheterList
                masonryview={false}
                listview
                favoriter={false}
                {...nyhetListProps}
              />
            </CenteredSection>
          )}
          {Fyrktitle}
          {fyrk && (
            <CenteredSection>
              <FyrkList
                masonryview={false}
                listview
                favoriter={false}
                {...fyrkListProps}
              />
            </CenteredSection>
          )}

          {profileListSection}
        </Grid>
      </Grid>
    );
  }
}

ProfileSingleCard.propTypes = {
  item: PropTypes.object,
  classes: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  fyrkstatus: getUserFyrkStatus(),
});
export default compose(withRouter, withStyles(styles))(ProfileSingleCard);
